<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "app",
  created() {
    let tmp_l = localStorage.getItem("italianaholding_language_setted");
    if (tmp_l) {
      if (tmp_l != "it") {
        this.lang = localStorage.getItem("italianaholding_language_setted");
      }
    } else {
      localStorage.setItem("italianaholding_language_setted", "it");
    }

    const route = this.$route.path;
    if (route.indexOf("/app") == 0 || route.indexOf("/crm") == 0) {
      if (
        localStorage.getItem(process.env.VUE_APP_USER_ID) &&
        localStorage.getItem(process.env.VUE_APP_USER_TOKEN)
      ) {
        const user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
        this.$http
          .get(`${process.env.VUE_APP_ROOT_API}/user/${user_id}`, {
            headers: {
              Authentication: localStorage.getItem(
                process.env.VUE_APP_USER_TOKEN
              ),
            },
          })
          .catch(() => {
            localStorage.removeItem(process.env.VUE_APP_USER_ID);
            localStorage.removeItem(process.env.VUE_APP_USER_TOKEN);
            window.location.href = "/app";
          });
      }
    }
  },
};
</script>
