<template lang="pug">
  main#clientform-view
    .card.form-data
      aside
        .slide#bigslide
      aside
        img.logo(src="@/assets/img/logo-grey.png")
        h2 Richiedi preventivo
        el-form(:model="form" ref="form")
          el-col(:span="11")
            el-form-item(label="Tipo di cliente" prop="client_type" required)
              el-radio-group(v-model="form.client_type")
                el-radio(border label="Privato")
                el-radio(border label="Azienda")

          el-col(:span="2")
            span.divide

          el-col(:span="11")
            el-form-item(label="Provenienza" prop="ref" required)
                el-select(v-model="form.ref" filterable allow-create)
                    el-option(v-for="i in refs" :key="i" :value="i")

          el-col(:span="11")
            el-form-item(label="Nome" prop="name" required)
              el-input(type="input" v-model="form.name")

          el-col(:span="2")
            span.divide

          el-col(:span="11")
            el-form-item(label="Cognome" prop="surname" required)
               el-input(type="input" v-model="form.surname")

          el-col(:span="11")
            el-form-item(label="Email" prop="email" required)
              el-input(type="email" v-model="form.email")

          el-col(:span="2")
            span.divide

          el-col(:span="11")
            el-form-item(label="Telefono" prop="phone" required)
              el-input(type="input" v-model="form.phone")

          el-col(:span="11")
            el-form-item(label="Indirizzo" prop="address" class="no-req" required)
              el-input(type="input" v-model="form.address")

          el-col(:span="2")
            span.divide

          div(v-if="form.client_type == 'Azienda'")
            el-col(:span="11")
              el-form-item(label="Ragione sociale" prop="business_name" class="no-req" required)
                el-input(type="input" v-model="form.business_name")

            el-col(:span="11")
              el-form-item(label="P.IVA" prop="piva" class="no-req" required)
                 el-input(type="input" v-model="form.piva")

            el-col(:span="2")
              span.divide

          el-col(:span="11")
            el-form-item(type="number" label="Budget mensile" prop="budget" required)
              el-input(type="input" v-model="form.budget")

          el-col(:span="2")
            span.divide

          el-col(:span="24")
            el-form-item(label="Note" prop="note" class="no-req" required)
              el-input(type="input" v-model="form.note")

          el-col(:span="24")
            el-form-item
              el-checkbox(label="Acconsento al trattamento dei dati" v-model="form.privacy")
              a.privacy(href="/privacy" target="_new") Termini di privacy

          el-button.go(type="success" @click="onSubmit()" :loading="loading") Richiedi un preventivo
          footer._form
            .social
              ol
                  li
                      a(href="#" target="_blank")
                          i#fb
                  li
                      a(href="#" target="_blank")
                          i#ig
                  li
                      a(href="#" target="_blank")
                          i#in
                  li
                      a(href="#" target="_blank")
                          i#tw

</template>

<script>
export default {
  name: "ItalianaCar",
  data() {
    return {
      loading: false,
      refs: [],
      form: {
        privacy: false,
        name: "",
        surname: "",
        email: "",
        business_name: "",
        piva: "",
        phone: "",
        change: "",
        budget: "",
        note: "",
        ref: "",
        address: "",
      },
    };
  },
  metaInfo: {
    title: "Form clienti - Liberotratto",
  },
  created() {
    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/refs`, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        this.refs = response.body.result.map((x) => x.name);
      });
  },
  methods: {
    onSubmit() {
      if (!this.$data.form.privacy) {
        this.$message({
          type: "error",
          message: "Devi accettare le condizioni sulla privacy",
        });
        return;
      }
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(String(this.$data.form.email).toLowerCase())) {
        this.$message({
          type: "error",
          message: "Hai inserito una email non valida",
        });
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.$data.form.name,
            surname: this.$data.form.surname,
            email: this.$data.form.email.toLowerCase(),
            phone: this.$data.form.phone,
            data: {
              business_name: this.$data.form.business_name,
              piva: this.$data.form.piva,
              client_type: this.$data.form.client_type,
              address: this.$data.form.address,
              budget: this.$data.form.budget,
            },
            ref: this.$data.form.ref,
            prospect: "Prospect",
            note: this.$data.form.note,
            divisions: ["web"],
            suppliers: [],
          };

          let path = `${process.env.VUE_APP_ROOT_API}/client`;

          this.loading = true;
          this.$http
            .post(path, params)
            .then(() => {
              this.$message({
                type: "success",
                message:
                  "Grazie per aver compilato il nostro form, sarete al più presto contattati, adesso verrete reindirizzati al nostro portfolio",
              });
              setTimeout(() => {
                window.location.href = "https://liberotratto.it/portfolio/";
              }, 1000);
            })
            .catch((e) => {
              let message = `${e.status} ${e.statusText}`;
              if (e.body.result) {
                message = e.body.result;
              }
              this.$message({
                type: "error",
                message,
              });
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="sass">
@import "../sass/publicform.sass"
</style>
