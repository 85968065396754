<template lang="pug">
    .main
        Sidebar(active="3")
        section.cms
            .card-form(v-if="createSupplier")
                el-card.box-card
                    .alert
                        el-alert(title="Fornitore salvato!" type="success" show-icon v-if="success")
                        el-alert(title="Errore!" type="error" :label="error" show-icon v-if="error")

                    el-form(label-width="120px" :model="supplierform" ref="supplierform")
                        el-form-item(label="Nome" prop="name" required)
                            el-input(type="input" v-model="supplierform.name")

                        el-form-item(label="Cellulare" prop="phone" required)
                            el-input(type="input" v-model="supplierform.phone")

                        el-form-item(label="Email" prop="email" required)
                            el-input(type="email" v-model="supplierform.email")

                        el-form-item(label="Indirizzo" prop="address")
                            el-input(type="input" v-model="supplierform.data.address")

                        el-form-item(label="Ragione sociale" prop="business_name")
                            el-input(type="input" v-model="supplierform.data.business_name")

                        el-form-item(label="Divisioni" prop="typegroups")
                            el-checkbox-group(v-model="supplierform.typegroups")
                                el-checkbox-button(:label="i.name" :name="i.name" v-for="i in typegroups" :key="i.name")

                        el-form-item(label="Lettera di presentazione" prop="letter")
                          input.el-input__inner(type="file" @change="handle_letter" accept="application/pdf" required)

                        el-form-item(label="Proposta commerciale" prop="commercial")
                          input.el-input__inner(type="file" @change="handle_commercial" accept="application/pdf")

                        el-form-item(label="Contratto di partnership" prop="contract")
                          input.el-input__inner(type="file" @change="handle_contract" accept="application/pdf")

                        el-form-item
                            el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva
                            a.el-button.el-button--info(href="/crm/suppliers")
                              i.el-icon-close
                              | Annulla

            .el-row
                el-button(type="primary" icon="el-icon-plus" @click="newSupplier") Nuovo fornitore 
                br
                br
                el-table(ref="multipleTable" :data="suppliers.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.email.toLowerCase().includes(search.toLowerCase()))" @selection-change="handleSelectionChange" stripe)
                    el-table-column(type="selection" width="50")
                    el-table-column(type="expand" width="30")
                        template(slot-scope="scope" v-if="scope.row.data")
                            p <b>Ragione sociale: </b> {{ scope.row.data.business_name }}
                            p <b>CF: </b> {{ scope.row.data.cf }}
                            p <b>P.IVA: </b> {{ scope.row.data.piva }}
                            p <b>PEC: </b> {{ scope.row.data.pec }}
                            p <b>Codice univoco: </b> {{ scope.row.data.code }}
                            p <b>Telefono fisso: </b> {{ scope.row.data.phone2 }}
                            p <b>Skype: </b> {{ scope.row.data.skype }}
                            p <b>Divisione: </b> {{ scope.row.data.division }}
                            p <b>Lettera di presentazione: </b>
                              a(
                                :href="'/supplier_letters/'+scope.row.data.letter"
                                target="_new"
                                v-if="scope.row.data.letter"
                              ) Clicca qui per vederla
                              b(v-else) NON ANCORA INSERITO
                            p <b>Proposta commerciale: </b>
                              a(
                                :href="'/supplier_letters/'+scope.row.data.commercial"
                                target="_new"
                                v-if="scope.row.data.commercial"
                              ) Clicca qui per vederla
                              b(v-else) NON ANCORA INSERITO
                            p <b>Contratto di partnership: </b>
                              a(
                                :href="'/supplier_letters/'+scope.row.data.contract"
                                target="_new"
                                v-if="scope.row.data.contract"
                              ) Clicca qui per vederla
                              b(v-else) NON ANCORA INSERITO
                            br
                            el-table(border :data="scope.row.data.addresses")
                                el-table-column(prop="address" label="Indirizzo")
                                el-table-column(prop="street_n" label="Numero")
                                el-table-column(prop="postal_code" label="CAP")
                                el-table-column(prop="city" label="Città")
                                el-table-column(prop="administrative_area_level_1" label="Regione")

                    el-table-column(label="Ragione sociale")
                        template(slot-scope="scope")
                          span(v-if="scope.row.data") {{ scope.row.data.business_name }}
                    el-table-column(label="Nome e cognome")
                        template(slot-scope="scope")
                          span {{ scope.row.name }}
                    el-table-column(prop="email" label="Email" width="200")
                    el-table-column(prop="phone" label="Telefono")
                    el-table-column(label="Indirizzo")
                        template(slot-scope="scope")
                          span(v-if="scope.row.data") {{ scope.row.data.address }}
                    el-table-column(label="Divisioni")
                        template(slot-scope="scope")
                          el-tag(v-for="division in scope.row.typegroups" type="info" effect="dark" :key="division.id") {{ division }}
                    el-table-column(align="right")
                        template(slot="header" slot-scope="scope")
                            el-input(v-model="search" size="mini" placeholder="Cerca")

                        template(slot-scope="scope")
                            el-button(size="mini" icon="el-icon-edit" title="Modifica" @click="handleEdit(scope.$index)")
                            el-button(size="mini" icon="el-icon-delete" title="Elimina" type="danger" @click="handleDelete(scope.row.id)")

                br
                br
                el-button(size="mini" type="danger" icon="el-icon-delete" @click="handleDeleteSelects()") Elimina righe selezionate   

</template>

<script>
import Sidebar from "./Sidebar";

export default {
  name: "Suppliers",
  components: {
    Sidebar,
  },
  data() {
    return {
      user_id: "",
      user_token: "",
      success: "",
      error: "",
      createSupplier: 0,
      selections: [],
      suppliers: [],
      typegroups: [],
      search: "",
      editform_id: null,
      supplierform: {
        letter: null,
        contract: null,
        commercial: null,
        name: "",
        email: "",
        phone: "",
        data: {
          business_name: "",
          address: "",
        },
        typegroups: [],
      },
    };
  },
  methods: {
    handleSelectionChange(val) {
      this.selections = val;
    },
    handleDeleteSelects() {
      let supplierids = this.$data.selections.map((e) => e.id);
      if (supplierids.length < 1) {
        this.$message({
          type: "info",
          message: "Nessuna riga selezionata",
        });
        return;
      }
      this.$http
        .delete(`${process.env.VUE_APP_ROOT_API}/suppliers?id=${supplierids}`, {
          headers: {
            Authentication: this.$data.user_token,
          },
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "Fornitori eliminati con successo",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        });
    },
    newSupplier(reset) {
      this.$data.createSupplier = !this.$data.createSupplier;
      if (reset) {
        this.$data.editform_id = null;
        this.$data.supplierform = {
          name: "",
          email: "",
          phone: "",
          typegroups: [],
          data: {
            business_name: "",
            address: "",
          },
        };
      }
    },
    handleEdit(index) {
      this.$data.editform_id = this.$data.suppliers[index].id;
      this.$data.supplierform = this.$data.suppliers[index];
      this.newSupplier();
    },
    handleDelete(supplierId) {
      this.$http
        .delete(`${process.env.VUE_APP_ROOT_API}/supplier/${supplierId}`, {
          headers: {
            Authentication: this.$data.user_token,
          },
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "Fornitore eliminato con successo",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        });
    },
    handle_letter($event) {
      this.supplierform.letter = $event.target.files[0];
    },
    handle_contract($event) {
      this.supplierform.contract = $event.target.files[0];
    },
    handle_commercial($event) {
      this.supplierform.commercial = $event.target.files[0];
    },
    onSubmit() {
      this.$refs["supplierform"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.$data.supplierform.name,
            email: this.$data.supplierform.email.toLowerCase(),
            phone: this.$data.supplierform.phone,
            typegroups: this.$data.supplierform.typegroups,
            data: JSON.stringify(this.$data.supplierform.data),
          };

          let formData = new FormData();
          for (const j of Object.keys(params)) formData.append(j, params[j]);

          for (const j of ["letter", "contract", "commercial"]) {
            if (this.supplierform[j]) {
              formData.append(j, this.supplierform[j]);
            }
          }

          var action = {
            supplier: {
              method: "",
              url: "",
              headers: {
                Authentication: localStorage.getItem(
                  process.env.VUE_APP_USER_TOKEN
                ),
                "Content-Type": "multipart/form-data",
              },
            },
          };
          if (this.$data.editform_id) {
            let path = `${process.env.VUE_APP_ROOT_API}/supplier/${this.$data.editform_id}`;
            action.supplier.method = "PUT";
            action.supplier.url = path;
          } else {
            let path = `${process.env.VUE_APP_ROOT_API}/supplier`;
            action.supplier.method = "POST";
            action.supplier.url = path;
          }

          var resource = this.$resource("supplier{/}", {}, action);
          resource
            .supplier(formData)
            .then(() => {
              this.$data.success = true;
              this.$data.error = false;
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((e) => {
              this.$data.error = `${e.body.status} ${e.body.result}`;
            });
        } else {
          return false;
        }
      });
    },
  },
  filters: {
  },
  created() {
    this.$data.user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
    this.$data.user_token = localStorage.getItem(
      process.env.VUE_APP_USER_TOKEN
    );

    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/suppliers`, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        let data = JSON.parse(response.bodyText).result;
        this.$data.suppliers = data;
      });

    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/typegroups`, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        let data = JSON.parse(response.bodyText).result;
        data.forEach((i) => {
          if (i.has_suppliers) this.$data.typegroups.push(i);
        });
      });
  },
};
</script>

<style lang="sass">
blockquote
    margin: 10px 0
    border: 1px #ebeef5 solid
    padding: 3px
    font-style: italic
</style>
