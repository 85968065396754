<template lang="pug">
    .main
        Sidebar(active="7")
        section.cms
            .el-row(v-loading="loading")
                el-button(type="secondary" icon="el-icon-link" @click="goToForm") Vai al form
                br
                br
                el-table(ref="multipleTable" :data="members.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.surname.toLowerCase().includes(search.toLowerCase()) || data.email.toLowerCase().includes(search.toLowerCase()))" @selection-change="handleSelectionChange" stripe)
                    el-table-column(type="selection" width="50")
                    el-table-column(type="expand" width="30")
                        template(slot-scope="scope")
                            p <b>Ragione sociale: </b> {{ scope.row.data.business_name }}
                            p <b>CF: </b> {{ scope.row.data.cf }}
                            p <b>P.IVA: </b> {{ scope.row.data.piva }}
                            p <b>PEC: </b> {{ scope.row.data.pec }}
                            p <b>Codice univoco: </b> {{ scope.row.data.code }}
                            p <b>Telefono mobile: </b> {{ scope.row.data.phone }}
                            p <b>Telefono fisso: </b> {{ scope.row.data.phone2 }}
                            p <b>Skype: </b> {{ scope.row.data.skype }}
                            p <b>Divisione: </b> {{ scope.row.data.division }}
                            p <b>Lettera di presentazione: </b>
                              a(
                                :href="'/supplier_letters/'+scope.row.data.letter"
                                target="_new"
                                v-if="scope.row.data.letter"
                              ) Clicca qui per vederla
                              b(v-else) NON INSERITO
                            p <b>Proposta commerciale: </b>
                              a(
                                :href="'/supplier_letters/'+scope.row.data.commercial"
                                target="_new"
                                v-if="scope.row.data.commercial"
                              ) Clicca qui per vederla
                              b(v-else) NON INSERITO
                            p <b>Contratto di partnership: </b>
                              a(
                                :href="'/supplier_letters/'+scope.row.data.contract"
                                target="_new"
                                v-if="scope.row.data.contract"
                              ) Clicca qui per vederla
                              b(v-else) NON INSERITO
                            br
                            el-table(border :data="scope.row.data.addresses")
                                el-table-column(prop="address" label="Indirizzo")
                                el-table-column(prop="street_n" label="Numero")
                                el-table-column(prop="postal_code" label="CAP")
                                el-table-column(prop="city" label="Città")
                                el-table-column(prop="administrative_area_level_1" label="Regione")

                    el-table-column(prop="created_at" label="Data")
                    el-table-column(prop="name" label="Nome")
                    el-table-column(prop="surname" label="Cognome")
                    el-table-column(prop="email" label="Email")

                    el-table-column(align="right")
                        template(slot="header" slot-scope="scope")
                            el-input(v-model="search" size="mini" placeholder="Cerca")

                        template(slot-scope="scope")
                            el-button(size="mini" icon="el-icon-s-claim" title="Trasforma in fornitore" type="primary" @click="handleTransformToSupplier(scope.row.id)")
                            el-button(size="mini" icon="el-icon-folder" title="Archivia" type="warning" @click="handleArchive(scope.row.id)")

                br
                br
                el-button(size="mini" type="warning" icon="el-icon-folder" @click="handleArchiveSelects()") Archivia righe
</template>

<script>
import Sidebar from "./Sidebar";

export default {
  name: "ItalianaSupplier",
  components: {
    Sidebar,
  },
  data() {
    return {
      loading: false,
      user_id: "",
      user_token: "",
      success: "",
      error: "",
      selections: [],
      members: [],
      search: "",
    };
  },
  methods: {
    goToForm() {
      window.location.href = `/ih-supplier`;
    },
    handleTransformToSupplier(memberId) {
      this.loading = true;
      this.$http
        .post(
          `${process.env.VUE_APP_ROOT_API}/member/${memberId}/supplier`,
          {},
          {
            headers: {
              Authentication: this.$data.user_token,
            },
          }
        )
        .then(() => {
          this.$message({
            type: "success",
            message: "Fornitore creato con successo",
          });
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSelectionChange(val) {
      this.selections = val;
    },
    handleArchiveSelects() {
      let memberids = this.$data.selections.map((e) => e.id);
      if (memberids.length < 1) {
        this.$message({
          type: "info",
          message: "Nessuna riga selezionata",
        });
        return;
      }
      this.$http
        .put(
          `${process.env.VUE_APP_ROOT_API}/member/archive`,
          { id: memberids },
          {
            headers: {
              Authentication: this.$data.user_token,
            },
          }
        )
        .then(() => {
          this.$message({
            type: "success",
            message: "Record archiviato con successo",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        });
    },
    handleArchive(memberId) {
      this.$http
        .put(
          `${process.env.VUE_APP_ROOT_API}/member/archive`,
          { id: memberId },
          {
            headers: {
              Authentication: this.$data.user_token,
            },
          }
        )
        .then(() => {
          this.$message({
            type: "success",
            message: "Record archiviato con successo",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        });
    },
  },
  created() {
    this.$data.user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
    this.$data.user_token = localStorage.getItem(
      process.env.VUE_APP_USER_TOKEN
    );

    this.$http
      .get(
        `${process.env.VUE_APP_ROOT_API}/members?typegroup=italianasupplier`,
        {
          headers: {
            Authentication: this.$data.user_token,
          },
        }
      )
      .then((response) => {
        let data = JSON.parse(response.bodyText).result;
        this.$data.members = data;
      });
  },
};
</script>

<style lang="sass">
.el-form-item__label
    line-height: 15px
</style>
