var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home"}},[_c('div',{staticClass:"layout--card"},[_c('el-card',{staticClass:"box-card"},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("Liberotratto")]),_c('div',{staticClass:"alert"},[(_vm.success)?_c('el-alert',{attrs:{"title":"Login effettuato!","type":"success","show-icon":""}}):_vm._e(),(_vm.error)?_c('el-alert',{attrs:{"title":"Errore!","type":"error","description":_vm.error,"show-icon":""}}):_vm._e()],1),_c('el-form',{ref:"form",attrs:{"label-width":"120px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"Email","prop":"email","rules":[
            {
              required: true,
              message: 'Non hai inserito nessuna email',
              trigger: 'blur',
            },
            {
              type: 'email',
              message: 'L\'indirizzo email non è valido',
              trigger: ['blur', 'change'],
            } ]}},[_c('el-input',{model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1),_c('el-form-item',{attrs:{"label":"Password","prop":"pass","rules":[
            {
              required: true,
              message: 'Non hai inserito nessuna password',
              trigger: 'blur',
            } ]}},[_c('el-input',{attrs:{"type":_vm.psw_format,"autocomplete":"off"},model:{value:(_vm.form.pass),callback:function ($$v) {_vm.$set(_vm.form, "pass", $$v)},expression:"form.pass"}}),_c('button',{staticClass:"check-psw",class:{
              see: _vm.psw_format == 'password',
              nosee: _vm.psw_format == 'text',
            },attrs:{"type":"button"},on:{"click":function($event){return _vm.change_psw_format()}}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Login")])],1),_c('el-form-item',[_c('el-link',{attrs:{"href":"/app/forgot","underline":false}},[_vm._v("Ho dimenticato la password")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }