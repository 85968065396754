import Vue from "vue";

import Clients from "../components/crm/Clients";
import Refs from "../components/crm/Refs";
import Suppliers from "../components/crm/Suppliers";
import Agents from "../components/crm/Agents";
import ItalianaSuppliersMembers from "../components/crm/ItalianaSuppliers";
import ItalianaAgentsMembers from "../components/crm/ItalianaAgents";

import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/element-ui.common.js";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/it";

import ItalianaSupplier from "../views/ihsupplier";
import ItalianaAgents from "../views/ihagents";
import ClientForm from "../views/clientform";

Vue.use(ElementUI, { locale });

const routes = [
  { path: "/crm", name: "clients", component: Clients },
  { path: "/crm/page/:page", name: "clients", component: Clients, props: true },
  {
    path: "/crm/refs",
    name: "refs",
    component: Refs,
    props: (route) => ({ refId: route.query.id }),
  },
  { path: "/crm/suppliers", name: "suppliers", component: Suppliers },
  { path: "/crm/agents", name: "agents", component: Agents },
  {
    path: "/crm/italianasuppliers",
    name: "italianasuppliers",
    component: ItalianaSuppliersMembers,
  },
  {
    path: "/crm/italianaagents",
    name: "italianaagents",
    component: ItalianaAgentsMembers,
  },

  { path: "/ih-supplier", name: "ih-supplier", component: ItalianaSupplier },
  { path: "/ih-agents", name: "ih-agents", component: ItalianaAgents },
  { path: "/client-form", name: "clientform", component: ClientForm },
];

export default routes.map((route) => {
  return { ...route, meta: { public: false } };
});
