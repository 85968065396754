<template lang="pug">
  main
    section#privacy-t
      .content(v-html="privacy.text")

</template>

<script>
export default {
  name: "privacy",
  components: {},
  data() {
    return {
      privacy: {},
    };
  },
  created: function () {
    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/page/Privacy/it`)
      .then((response) => {
        var data = JSON.parse(response.data.result.data);
        this.$data.privacy = data;
      });
  },
};
</script>

<style lang="sass">
#privacy-t
    margin: 0
    .content
        padding: 100px 0
        text-align: start
        h1
            font-size: 3em
            text-align: center
        p
            font-size: 1em
            strong
                font-size: 1.3em
</style>
