<template lang="pug">
  main
    .card
      h2 Diventa agente
      hr
      br
      br

      el-form(:model="form" ref="form")
        el-col(:span="11")
          el-form-item(label="Nome" prop="name" required)
            el-input(type="input" v-model="form.name")

        el-col(:span="2" class="line")

        el-col(:span="11")
          el-form-item(label="Cognome" prop="surname" required)
            el-input(type="input" v-model="form.surname")

        el-col(:span="8")
          el-form-item(label="Email" prop="email" required)
            el-input(type="email" v-model="form.email")

        el-col(:span="2" class="line")

        el-col(:span="6")
          el-form-item(label="Telefono mobile" prop="phone" required)
            el-input(type="input" v-model="form.phone")

        el-col(:span="2" class="line")

        el-col(:span="6")
          el-form-item(label="Skype" prop="skype")
            el-input(type="input" v-model="form.skype")

        el-col(:span="11")
          el-form-item(label="Divisione" prop="division" required style="width: 100%; text-align: start")
            el-select(v-model="form.division" placeholder="Seleziona una divisione" style="width: 100%")
              el-option(label="Consulente Globale" value="Consulente Globale")
              el-option(label="Italiana Medical" value="Italiana Medical")
              el-option(:label="i.name.it" :value="i.name.it" v-for="i in divisions" :key="i.name.it")

        el-col(:span="2" class="line")

        el-col(:span="11")
          el-form-item(label="Tipo di cliente")
            el-radio-group(v-model="form.client_type")
              el-radio(border label="Privato")
              el-radio(border label="Azienda")

        el-col(:span="24" v-if="form.client_type == 'Azienda'")
          el-col(:span="11")
            el-form-item(label="Ragione sociale" prop="business_name" class="no-req")
              el-input(type="input" v-model="form.business_name")

          el-col(:span="2" class="line")

          el-col(:span="11")
            el-form-item(label="P.IVA" prop="piva" class="no-req")
               el-input(type="input" v-model="form.piva")
        div(v-else)
          el-col(:span="24")
            el-form-item(label="Codice fiscale" prop="cf" class="no-req")
              el-input(type="input" v-model="form.cf")

        div(v-for="city, index in form.cities")
          el-col.addr_head(:span="24")
            h4 Città n. {{ index+1}}
            div(style="justify-self:end")
              el-button(type="danger" size="mini" circle icon="el-icon-close" @click="del_city(index)" v-if="city.visible")
              el-button(type="warning" size="mini" icon="el-icon-refresh-right" @click="del_city(index, 1)" v-else) Recupera

          el-col(:span="24")
            el-form-item
              el-select(
                v-model="city.region"
                placeholder="Seleziona una regione"
                style="width: 100%"
                :disabled="city.visible ? false : true"
                filterable
                @change="clear_cities(index)")
                el-option(:value="i" v-for="i in regions" :key="i")

          el-col(:span="24")
            el-form-item
              el-select(
                v-model="city.city"
                placeholder="Seleziona le città"
                style="width: 100%"
                :disabled="city.visible ? false : true"
                multiple
                filterable)
                  el-option(:value="i.name" v-for="i in italy_cities.filter(i => i.region == city.region)" :key="i.name")

          el-col(:span="24")
            br
            hr
            br

        el-col(:span="24")
          el-form-item
            el-button(type="primary" @click="new_city" icon="el-icon-plus") Nuova città
        br

        el-col(:span="24")
          el-form-item(label="Curriculum vitae" prop="letter" required)
            input.el-input__inner(type="file" @change="handle_letter" accept="application/pdf" required)

        el-col(:span="24")
          el-form-item
            el-checkbox(label="Accetto i termini e condizioni della privacy" border v-model="form.privacy")
          a(href="/privacy" target="_new") Leggi il contratto sulla privacy

        el-col(:span="24")
          el-form-item
            br
            el-button(type="success" @click="onSubmit()" icon="el-icon-check" :loading="loading") Salva

</template>

<script>
export default {
  name: "ItalianaAgents",
  data() {
    return {
      i_addr: 0,
      divisions: [],
      loading: false,
      form: {
        privacy: false,
        letter: null,
        name: "",
        surname: "",
        email: "",
        business_name: "",
        piva: "",
        cf: "",
        client_type: "",
        phone: "",
        skype: "",
        division: "",
        cities: [],
      },
      regions: [
        "Abruzzo",
        "Basilicata",
        "Calabria",
        "Campania",
        "Emilia-Romagna",
        "Friuli-Venezia Giulia",
        "Lazio",
        "Liguria",
        "Lombardia",
        "Marche",
        "Molise",
        "Piemonte",
        "Puglia",
        "Sardegna",
        "Sicilia",
        "Toscana",
        "Trentino-Alto Adige/Südtirol",
        "Umbria",
        "Valle d'Aosta/Vallée d'Aoste",
        "Veneto",
      ],
      italy_cities: [],
    };
  },
  created() {
    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/divisions?params=name`)
      .then((response) => {
        var data = response.data.result;
        this.$data.divisions = data;
      });
    this.$data.italy_cities = require("@/assets/comuni.json");
  },
  methods: {
    handle_letter($event) {
      this.form.letter = $event.target.files[0];
    },
    del_city(index, way) {
      this.$data.form.cities[index].visible = way;
    },
    new_city() {
      this.$data.form.cities.push({
        visible: true,
        region: "",
        city: "",
      });
    },
    clear_cities(index) {
      this.$data.form.cities[index].city = [];
    },
    onSubmit() {
      if (!this.$data.form.privacy) {
        this.$message({
          type: "error",
          message: "Devi accettare le condizioni sulla privacy",
        });
        return;
      }
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!re.test(String(this.$data.form.email).toLowerCase())) {
        this.$message({
          type: "error",
          message: "Hai inserito una email non valida",
        });
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let params = {
            letter: this.$data.form.letter,
            name: this.$data.form.name,
            surname: this.$data.form.surname,
            email: this.$data.form.email,
            data: JSON.stringify({
              phone: this.$data.form.phone,
              business_name: this.$data.form.business_name,
              piva: this.$data.form.piva,
              cf: this.$data.form.cf,
              client_type: this.$data.form.client_type,
              skype: this.$data.form.skype,
              cities: this.$data.form.cities.filter((city) => city.visible),
              division: this.$data.form.division,
            }),
            typegroup: "agents",
            suppliers: [],
          };
          console.log(params);
          let formData = new FormData();
          formData.append("cv", params.letter);
          formData.append("name", params.name);
          formData.append("surname", params.surname);
          formData.append("email", params.email);
          formData.append("data", params.data);
          formData.append("typegroup", params.typegroup);
          formData.append("suppliers", params.suppliers);

          let path = `${process.env.VUE_APP_ROOT_API}/member`;

          this.loading = true;
          this.$http
            .post(path, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => {
              this.$message({
                type: "success",
                message: "Dati inviato con successo!",
              });
              setTimeout(() => {
                window.location.href = "https://www.italianaholding.it";
              }, 1000);
            })
            .catch((e) => {
              this.$message({
                type: "error",
                message: `${e.status} ${e.statusText}`,
              });
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="sass">
label.el-form-item__label
  width: 100%
  text-align: start
</style>

<style lang="sass" scoped>
main
  width: 50%
  margin: 20px auto

.line
  min-height: 1px

.addr_head
  display: grid
  grid-template-columns: 85% 15%
  margin-bottom: 20px
  align-items: center

ol
 li
   list-style: none
   line-height: 25px

@media (max-width: 768px)
  main
    width: 90%
  .el-col-11
    width: 100%
</style>
