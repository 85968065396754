<template lang="pug">
    aside.cms
        el-menu.el-menu-vertical-demo(:default-active="active")
            img.mobile-logo(src="../../assets/img/ih-icon.png" alt="mobile icon")
            img(src="../../assets/img/logo-grey.png" alt="Logo" style="width: 135px")
            br
            a(href="/crm")
              el-menu-item(index="-1")
                i.el-icon-data-analysis
                span CRM

            a(href="/app/dashboard")
              el-menu-item(index="1")
                i.el-icon-user
                span Utenti

            a(href="/app/pages")
              el-menu-item(index="2")
                i.el-icon-notebook-2
                span Pagine

            a(href="/app/divisions")
              el-menu-item(index="4")
                i.el-icon-school
                span Divisioni

            hr

            el-menu-item(style="color:red" @click="logout")
              i.el-icon-switch-button
              span Logout

</template>

<script>
export default {
  name: "Sidebar",
  props: ["active"],
  data() {
    return {
    };
  },
  methods: {
    logout() {
      localStorage.removeItem(process.env.VUE_APP_USER_TOKEN);
      localStorage.removeItem(process.env.VUE_APP_USER_ID);
      window.location.href = "/app";
    },
  },
  created() {
    let user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/user/${user_id}`, {
        headers: {
          Authentication: localStorage.getItem(process.env.VUE_APP_USER_TOKEN),
        },
      })
      .then(() => {})
      .catch(() => {
        this.logout();
      });
  },
};
</script>

<style lang="sass">
@media screen and (max-width: 768px)
  aside.cms ul
    overflow-x: scroll
    img
      display: none
    .mobile-logo
      margin: 25px 10px 25px 15px
      display: block
</style>
