<template lang="pug">
    #forgotPassword
        .layout--card
        el-card.box-card
            .alert
                el-alert(title="Email inviata!" v-if="!hash && success" description="Controlla la tua casella di posta per reimpostare la password" type="success" show-icon)
                el-alert(title="Password salvata" v-else-if="hash && success" type="success" show-icon)
                el-alert(title="Errore!" type="error" :description="error" show-icon v-if="error")

            h1(style="text-align:center") Reimposta password
            br
            el-form(ref="form" label-width="120px" :model="form" v-if="!hash")
                el-form-item(label="Email" prop="email" required)
                    el-input(type="email" v-model="form.email" autocomplete="on")
                el-form-item
                    el-button(type="primary" @click="submit" :loading="loading") Invia
            el-form(ref="form" label-width="120px" :model="form" v-else-if="!error_hash")
                el-form-item(label="Password" prop="password" required)
                    el-input(type="password" v-model="form.password" autocomplete="off" minlength="8")
                el-form-item(label="Ripeti password" prop="password2" required)
                    el-input(type="password" v-model="form.password2" autocomplete="off")

                el-form-item
                    el-button(type="primary" @click="submitPsw" :loading="loading") Salva


</template>

<script>
export default {
  name: "forgotPassword",
  data() {
    return {
      loading: false,
      hash: this.$route.params.hash,
      form: {
        email: "",
        password: "",
        password2: "",
      },
      success: "",
      error: "",
      error_hash: 0,
    };
  },
  methods: {
    submit() {
      this.$data.error = this.$data.success = "";
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$data.loading = true;
          this.$http
            .get(`${process.env.VUE_APP_ROOT_API}/user/forgot_password`, {
              params: { email: this.$data.form.email },
            })
            .then(() => {
              this.$data.success = true;
            })
            .catch((e) => {
              this.$data.error = `${e.body.status} ${e.body.result}`;
            })
            .finally(() => {
              this.$data.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    submitPsw() {
      this.$data.error = this.$data.success = "";
      this.$refs["form"].validate((valid) => {
        if (this.$data.form.password != this.$data.form.password2) {
          this.$data.error = "Le password non coincidono";
        }
        if (this.$data.form.password.length < 8) {
          this.$data.error = "La password dev'essere di almeno 8 caratteri";
        } else if (valid) {
          this.$data.loading = true;
          this.$http
            .put(
              `${process.env.VUE_APP_ROOT_API}/user/new-password/${this.$data.hash}`,
              {
                password: this.$data.form.password,
              }
            )
            .then(() => {
              this.$data.success = true;
            })
            .catch((e) => {
              this.$data.error = `${e.body.status} ${e.body.result}`;
            })
            .finally(() => {
              this.$data.loading = false;
            });
        }
      });
    },
  },
  created() {
    if (this.$data.hash) {
      this.$http
        .get(`${process.env.VUE_APP_ROOT_API}/user/hash_password`, {
          params: { hash_password: this.$data.hash },
        })
        .then(() => {})
        .catch(() => {
          this.$data.error =
            "Non esiste nessun utente associato a questo alias";
          this.$data.error_hash = 1;
        });
    }
  },
};
</script>
