<template>
  <div id="home">
    <div class="layout--card">
      <el-card class="box-card">
        <h1 style="text-align: center">Liberotratto</h1>
        <div class="alert">
          <el-alert
            title="Login effettuato!"
            type="success"
            show-icon
            v-if="success"
          ></el-alert>
          <el-alert
            title="Errore!"
            type="error"
            :description="error"
            show-icon
            v-if="error"
          ></el-alert>
        </div>
        <el-form ref="form" label-width="120px" :model="form">
          <el-form-item
            label="Email"
            prop="email"
            :rules="[
              {
                required: true,
                message: 'Non hai inserito nessuna email',
                trigger: 'blur',
              },
              {
                type: 'email',
                message: 'L\'indirizzo email non è valido',
                trigger: ['blur', 'change'],
              },
            ]"
          >
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <el-form-item
            label="Password"
            prop="pass"
            :rules="[
              {
                required: true,
                message: 'Non hai inserito nessuna password',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              :type="psw_format"
              v-model="form.pass"
              autocomplete="off"
            ></el-input>
            <button
              type="button"
              class="check-psw"
              @click="change_psw_format()"
              :class="{
                see: psw_format == 'password',
                nosee: psw_format == 'text',
              }"
            ></button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">Login</el-button>
          </el-form-item>
          <el-form-item>
            <el-link href="/app/forgot" :underline="false"
              >Ho dimenticato la password</el-link
            >
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {},
  data() {
    return {
      form: {
        email: "",
        pass: "",
      },
      psw_format: "password",
      success: "",
      error: "",
    };
  },
  methods: {
    change_psw_format() {
      this.$data.psw_format =
        this.$data.psw_format == "password" ? "text" : "password";
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$http
            .post(`${process.env.VUE_APP_ROOT_API}/login`, {
              is_admin: 1,
              email: this.$data.form.email,
              password: this.$data.form.pass,
            })
            .then((response) => {
              let data = JSON.parse(response.bodyText).result;
              localStorage.setItem(process.env.VUE_APP_USER_ID, data.id);
              localStorage.setItem(process.env.VUE_APP_USER_TOKEN, data.token);
              this.success = true;
              setTimeout(() => {
                window.location.href = "/crm";
              }, 1000);
              this.$data.success = true;
              this.$data.error = false;
            })
            .catch((e) => {
              this.$data.error = `${e.body.status} ${e.body.result}`;
              this.$swal({
                buttons: false,
                timer: 1000,
                title: this.$data.error,
                icon: "error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
  beforeMount() {
    if (localStorage.getItem(process.env.VUE_APP_USER_TOKEN)) {
      this.$http
        .post(
          `${process.env.VUE_APP_ROOT_API}/login`,
          { is_admin: 1 },
          {
            headers: {
              Authentication: localStorage.getItem(
                process.env.VUE_APP_USER_TOKEN
              ),
            },
          }
        )
        .then((response) => {
          let data = JSON.parse(response.bodyText).result;
          localStorage.setItem(process.env.VUE_APP_USER_ID, data.id);
          localStorage.setItem(process.env.VUE_APP_USER_TOKEN, data.token);

          window.location.href = "/crm";
        })
        .catch(() => {
          localStorage.removeItem(process.env.VUE_APP_USER_ID);
          localStorage.removeItem(process.env.VUE_APP_USER_TOKEN);
        });
    }
  },
};
</script>

<style>
ol,
ul {
  list-style-position: inside;
}
.check-psw {
  width: 30px;
  height: 30px;
  background-color: #409eff;
  border: none;
  position: absolute;
  right: 0;
  margin: 4px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 3px;
  cursor: pointer;
}

.check-psw.see {
  background-image: url("../../assets/img/eye.png");
}

.check-psw.nosee {
  background-image: url("../../assets/img/eye-close.png");
}

.el-card {
  width: 50%;
  margin: 100px auto;
}

.alert {
  margin: 15px 0;
}

aside.cms {
  width: 200px;
  height: 100%;
  position: fixed;
  top: 0;
  overflow-y: auto;
}

aside.cms ul {
  height: 100%;
}

aside.cms ul .mobile-logo {
  display: none;
}

aside.cms ul img {
  display: block;
  margin: 30px auto;
}

#app a {
  text-decoration: none;
}

section.cms {
  position: relative;
  left: 230px;
  width: 80%;
  top: 20px;
  margin-top: 10px;
}

aside.cms a {
  text-decoration: none;
}

.info {
  margin-bottom: 20px;
}

.card-form {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.card-form .el-card {
  margin-top: 50px;
}

.el-col a {
  text-decoration: none;
}

.dashboard .el-card {
  margin: 0 auto 10px;
}

@media all and (max-width: 500px) {
  aside.cms {
    width: 100%;
    height: 70px;
    z-index: 1;
  }
  section.cms {
    left: 0;
    width: 95%;
    top: 100px;
    margin: 0 auto;
  }
  .dashboard .el-card {
    width: 90%;
  }
  .el-menu {
    display: flex;
  }
  .el-menu img {
    display: none;
  }
  .el-menu a,
  .el-menu li {
    height: 70px;
  }
  .el-menu-item {
    line-height: 70px !important;
    padding: 0 10px !important;
  }
  .el-menu-item span {
    display: none;
  }
  .el-menu hr {
    display: none;
  }
  .el-menu-item i.el-icon-switch-button {
    color: red;
  }
  .el-card {
    width: 100%;
  }
  .el-form-item {
    display: grid;
  }
  .el-form-item__label {
    width: 100% !important;
    text-align: center !important;
  }
  .el-form-item__content {
    margin: 0 !important;
  }
  .card-form {
    overflow: scroll;
  }
  .el-input-number,
  .el-select {
    width: 100%;
  }
  .el-form-item__content .el-button--primary {
    margin: 0 auto;
    display: block;
  }
  .el-card.box-card {
    width: 90%;
  }
}
</style>
