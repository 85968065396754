<template lang="pug">
   .pages
        Sidebar(active="2")
        section.cms
            .el-row(v-if="!pageId")
                .el-col(v-for="page in pages")
                    a(:href="'?id='+page.id")
                        el-card(shadow="hover" style="margin: 5px auto; width:100%")
                            div(style="float:left")
                                h2 {{ page.name }}
                                span {{ page.description }}
                            .card-actions
                                span(style="float:right") Ultima modifica: {{ page.updated_at }}
                                div
                                    a.el-button(:href="`?id=${page.id}&lang=${l}`" v-for="l in availableLangs") {{ l }}
            .el-row(v-else)
                h2 Modifica pagina
                br
                div
                    span Cambia lingua: 
                    a.el-button(:href="`?id=${pageId}&lang=${l}`" :class="{'is-disabled': l == lang}" v-for="l in availableLangs") {{ l }}
                
                br
                .alert
                    el-alert(title="Pagina salvata!" type="success" show-icon v-if="success")
                    el-alert(title="Errore!" type="error" :description="error" show-icon v-if="error")
                br
                el-form(label-width="120px" :model="pageform" ref="pageform")
                    el-form-item(:label="key" prop="message" v-for="_, key in pageform" v-bind:key="key")
                        el-input(type="textarea" v-model="pageform[key]")

                    el-form-item
                        el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva modifiche
                        a.el-button.el-button--info(href="/app/pages")
                            i.el-icon-back
                            span Torna indietro
</template>

<script>
import Sidebar from "./Sidebar";

export default {
  name: "Pages",
  components: {
    Sidebar,
  },
  props: ["pageId", "lang"],
  data() {
    return {
      pages: [],
      success: "",
      error: "",
      createPage: false,
      pageform: {},
      availableLangs: process.env.VUE_APP_LANGS.split(","),
    };
  },
  methods: {
    onSubmit() {
      this.$refs["pageform"].validate((valid) => {
        if (valid) {
          this.$http
            .put(
              `${process.env.VUE_APP_ROOT_API}/page/${this.$props.pageId}/${this.$props.lang}`,
              { data: JSON.stringify(this.$data.pageform) },
              {
                headers: {
                  Authentication: localStorage.getItem(
                    process.env.VUE_APP_USER_TOKEN
                  ),
                },
              }
            )
            .then(() => {
              this.$data.success = true;
              this.$swal({
                buttons: false,
                timer: 1000,
                title: "Pagina salvata!",
                type: "success",
              });
              this.$data.error = false;
            })
            .catch((e) => {
              this.$data.error = `${e.body.status} ${e.body.result}`;
            });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    if (
      !(
        localStorage.getItem(process.env.VUE_APP_USER_ID) &&
        localStorage.getItem(process.env.VUE_APP_USER_TOKEN)
      )
    ) {
      window.location.href = "/app";
    }

    if (!this.$props.pageId) {
      this.$http
        .get(`${process.env.VUE_APP_ROOT_API}/pages`, {
          headers: {
            Authentication: localStorage.getItem(
              process.env.VUE_APP_USER_TOKEN
            ),
          },
        })
        .then((response) => {
          let data = JSON.parse(response.bodyText).result;
          this.$data.pages = data;
        })
        .catch(() => {});
    } else {
      if (!this.$props.lang) {
        setTimeout(() => {
          window.location.href += "&lang=it";
        }, 500);
      } else {
        this.$http
          .get(
            `${process.env.VUE_APP_ROOT_API}/page/${this.$props.pageId}/${this.$props.lang}`
          )
          .then((response) => {
            let data = JSON.parse(response.bodyText).result.data;
            this.$data.pageform = JSON.parse(data);
          })
          .catch(() => {
            window.location.href = "/app/pages";
          });
      }
    }
  },
};
</script>

<style lang="sass">
.el-card__body
    display: flow-root
</style>
