<template lang="pug">
    aside.cms
        el-menu.el-menu-vertical-demo(:default-active="active")
            img.mobile-logo(src="../../assets/img/ih-icon.png" alt="mobile icon")
            img(src="../../assets/img/logo-grey.png" alt="Logo" style="width: 135px")
            br
            a(href="/app/dashboard")
              el-menu-item(index="-1")
                i.el-icon-s-home
                span Dashboard

            a(href="/crm/refs")
              el-menu-item(index="4")
                i.el-icon-user-solid
                span Sorgenti

            a(href="/crm/")
              el-menu-item(index="1")
                i.el-icon-user
                span Clienti
 
            a(href="/crm/suppliers")
              el-menu-item(index="3")
                i.el-icon-s-custom
                span Fornitori 

            a(href="/crm/agents")
              el-menu-item(index="8")
                i.el-icon-s-custom
                span Agenti 

            hr

            a(href="/crm/italianasuppliers")
              el-menu-item(index="7")
                i.el-icon-notebook-1
                span Form fornitori

            a(href="/crm/italianaagents")
              el-menu-item(index="9")
                i.el-icon-notebook-1
                span Form agenti

            hr

            el-menu-item(style="color:red" @click="logout")
              i.el-icon-switch-button
              span Logout

</template>

<script>
export default {
  name: "Sidebar",
  props: ["active"],
  data() {
    return {
      unread: 0,
    };
  },
  methods: {
    logout() {
      localStorage.removeItem(process.env.VUE_APP_USER_TOKEN);
      localStorage.removeItem(process.env.VUE_APP_USER_ID);
      window.location.href = "/app";
    },
  },
  created() {
    let user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
    if (!user_id) {
      window.location.href = "/app";
    }
  },
};
</script>

<style lang="sass">
.contacts-unreaded
  width: 10px
  height: 10px
  background: #03a84e
  display: inline-block
  border-radius: 100%
  margin: 0 10px
</style>
