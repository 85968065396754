<template lang="pug">
    .refs
        Sidebar(active="4")
        section.cms
            .card-form(v-if="createRef || refId")
                el-card.box-card
                    .alert
                        el-alert(title="Sorgente salvata!" type="success" show-icon v-if="success")
                        el-alert(title="Errore!" type="error" :label="error" show-icon v-if="error")

                    el-form(label-width="120px" :model="refform" ref="refform")
                        el-form-item(label="Nome" prop="name" required)
                            el-input(type="input" v-model="refform.name")

                        el-form-item
                            el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva modifiche
                            el-button(type="info" @click="newRef()" icon="el-icon-close" v-if="!refId") Annulla
                            a(class="el-button el-button--info" href="/crm/refs" v-else) <i class="el-icon-close"></i> Annulla                

            .el-row(v-if="!refId")
                el-button(type="primary" icon="el-icon-plus" @click="newRef") Nuovo sorgente
                .el-col(v-for="ref in refs")
                    el-card(shadow="hover" style="margin: 5px auto; width:100%")
                        div(style="float:left")
                            h2 {{ ref.name }}
                        .card-actions
                          span(style="float:right") Creazione: {{ ref.created_at }}
                          div
                            a(class="el-button el-button--info" :href="'?id='+ref.id") <i class="el-icon-edit"></i> Modifica
                            el-button(type="danger" @click="deleteRef(ref.id)" icon="el-icon-delete") Elimina
</template>

<script>
import Sidebar from "./Sidebar";

export default {
  name: "News",
  components: {
    Sidebar,
  },
  props: ["refId"],
  data() {
    return {
      refs: [],
      success: "",
      error: "",
      createRef: false,
      refform: {
        name: "",
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs["refform"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.$data.refform.name,
            email: this.$data.refform.email,
            password: this.$data.refform.password,
          };
          var path;
          if (this.$props.refId) {
            path = `${process.env.VUE_APP_ROOT_API}/ref/${this.$props.refId}`;
          } else {
            path = `${process.env.VUE_APP_ROOT_API}/ref`;
          }

          if (this.$props.refId) {
            this.$http
              .put(path, params, {
                headers: {
                  Authentication: localStorage.getItem(
                    process.env.VUE_APP_USER_TOKEN
                  ),
                },
              })
              .then(() => {
                this.$data.success = true;
                this.$swal({
                  buttons: false,
                  timer: 1000,
                  title: "Sorgente salvata!",
                  icon: "success",
                });
                this.$data.error = false;
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((e) => {
                this.$data.error = `${e.body.status} ${e.body.result}`;
              });
          } else {
            this.$http
              .post(path, params, {
                headers: {
                  Authentication: localStorage.getItem(
                    process.env.VUE_APP_USER_TOKEN
                  ),
                },
              })
              .then(() => {
                this.$data.success = true;
                this.$data.error = false;
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((e) => {
                this.$data.error = `${e.body.status} ${e.body.result}`;
              });
          }
        } else {
          return false;
        }
      });
    },
    newRef() {
      this.$data.createRef = !this.$data.createRef;
    },
    deleteRef(refId) {
      this.$http
        .delete(`${process.env.VUE_APP_ROOT_API}/ref/${refId}`, {
          headers: {
            Authentication: localStorage.getItem(
              process.env.VUE_APP_USER_TOKEN
            ),
          },
        })
        .then(() => {
          window.location.href = "/crm/refs";
        });
    },
  },
  created() {
    if (
      !(
        localStorage.getItem(process.env.VUE_APP_USER_ID) &&
        localStorage.getItem(process.env.VUE_APP_USER_TOKEN)
      )
    ) {
      window.location.href = "/crm";
    }

    if (!this.$props.refId) {
      this.$http
        .get(`${process.env.VUE_APP_ROOT_API}/refs`, {
          headers: {
            Authentication: localStorage.getItem(
              process.env.VUE_APP_USER_TOKEN
            ),
          },
        })
        .then((response) => {
          let data = JSON.parse(response.bodyText).result;
          this.$data.refs = data;
        });
    } else {
      this.$http
        .get(`${process.env.VUE_APP_ROOT_API}/ref/${this.$props.refId}`, {
          headers: {
            Authentication: localStorage.getItem(
              process.env.VUE_APP_USER_TOKEN
            ),
          },
        })
        .then((response) => {
          let data = JSON.parse(response.bodyText).result;
          this.$data.refform = data;
        })
        .catch(() => {
          window.location.href = "/crm/refs";
        });
    }
  },
};
</script>

<style lang="sass">
.el-card__body
	display: flow-root

.el-button
	font-family: 'system-ui', sans-serif

.card-actions
	float: right
</style>
