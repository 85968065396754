<template lang="pug">
    .main
        Sidebar(active="1")
        section.cms
            .card-form(v-if="send_to_supplier" )
                el-card.box-card
                    el-form(:model="supplierform" ref="supplierform")
                        el-form-item(label="Fornitori a cui inviare i dati" prop="suppliers")
                            el-checkbox-group(v-model="supplierform.suppliers" style="display:table;width:100%")
                                el-checkbox-button(:label="i.name+' <'+i.email+'>'" :name="i.name" v-for="i in supplierform.typegroup" :key="i.name")

                        br
                        el-tabs(type="border-card")
                            el-tab-pane(:label="send_to_supplier.email")
                                h4 Dati da inviare
                                br
                                el-form-item.send_data
                                    div
                                        input(type="checkbox" id="name" v-model="supplierform.clients.name")
                                        label(for="name") Nome: <b>{{ send_to_supplier.name }}</b> 
                                    div
                                        input(type="checkbox" id="surname" v-model="supplierform.clients.surname")
                                        label(for="surname") Cognome: <b>{{ send_to_supplier.surname }}</b> 
                                    div
                                        input(type="checkbox" id="email" v-model="supplierform.clients.email")
                                        label(for="email") Email: <b>{{ send_to_supplier.email }}</b> 
                                    div
                                        input(type="checkbox" id="phone" v-model="supplierform.clients.phone")
                                        label(for="phone") Telefono: <b>{{ send_to_supplier.phone }}</b> 
                                    div(v-for="i in Object.keys(supplierform.clients.data)")
                                        input(type="checkbox" :id="i" v-model="supplierform.clients.data[i]")
                                        label(:for="i") {{ trans_keys(i) }}: <b>{{ send_to_supplier.data[i] }}</b> 
                        br
                        el-form-item(label="Note" prop="note")
                            el-input(type="textarea" v-model="supplierform.note")

                        el-form-item(v-loading="loading")
                            el-button(type="success" @click="onSubmitSupplier()" icon="el-icon-check") Invia
                            el-button(type="info" @click="handleSupplier(-1)" icon="el-icon-close") Annulla
            .card-form(v-if="createClient")
                el-card.box-card
                    .alert
                        el-alert(title="Cliente salvato!" type="success" show-icon v-if="success")
                        el-alert(title="Errore!" type="error" :label="error" show-icon v-if="error")
                          p {{ error_detail }}

                    el-form(label-width="120px" :model="clientform" ref="clientform")
                        el-form-item(label="Nome" prop="name" required)
                            el-input(type="input" v-model="clientform.name")

                        el-form-item(label="Cognome" prop="surname")
                            el-input(type="input" v-model="clientform.surname")

                        el-form-item(label="Email" prop="email" required)
                            el-input(type="email" v-model="clientform.email")

                        el-form-item(label="Telefono mobile" prop="phone" required)
                            el-input(type="input" v-model="clientform.phone" :maxlength="13")

                        el-card(style="margin: 0 0 22px 0;width: 100%;")
                            el-form-item(:label="'Telefono nro. ' + (i+1)" v-for="_, i in clientform.data.other_phones" :key="i")
                                el-input(type="input" v-model="clientform.data.other_phones[i]" style="width: 80%") 
                                el-button(type="danger" icon="el-icon-delete" style="margin-left: 20px" @click="removeOtherPhone(i)")

                            el-button(icon="el-icon-plus" type="success" @click="addOtherPhone") Aggiungi numero

                        el-form-item(label="Sorgente" prop="ref" required)
                            el-select(v-model="clientform.ref" filterable)
                                el-option(v-for="i in refs.concat(agents)" :key="i" :value="i")

                        el-form-item(label="Stato" prop="prospect" required)
                            el-select(v-model="clientform.prospect")
                                el-option(:label="i" :value="i" v-for="i in prospects" :key="i")

                        el-form-item(label="Ragione sociale" prop="business_name")
                            el-input(type="input" v-model="clientform.data.business_name")

                        el-form-item(label="Indirizzo" prop="address")
                            el-input(type="input" v-model="clientform.data.address")

                        el-form-item(label="P.IVA" prop="vat")
                            el-input(type="input" v-model="clientform.data.vat")

                        el-form-item(label="Assocazione di categoria" prop="category")
                            el-input(type="input" v-model="clientform.data.category")

                        el-form-item(label="Codice univoco" prop="cod")
                            el-input(type="input" v-model="clientform.data.cod")

                        el-form-item(label="Sito web" prop="site")
                            el-input(type="input" v-model="clientform.data.site")

                        el-form-item(label="Telefono fisso" prop="phone2")
                            el-input(type="input" v-model="clientform.data.phone2")

                        el-form-item(label="Data di nascita" prop="birthday")
                            el-date-picker(type="date" v-model="clientform.data.birthday" format="dd/MM/yyyy")

                        el-form-item(label="Skype" prop="skype")
                            el-input(type="input" v-model="clientform.data.skype")

                        el-form-item(label="PEC" prop="pec")
                            el-input(type="input" v-model="clientform.data.pec")

                        el-form-item(label="Note" prop="note")
                            el-input(type="textarea" v-model="clientform.note")

                        el-form-item(label="Divisione")
                            el-select(v-model="clientform.divisions" multiple filterable style="width:100%")
                                el-option(:value="i.name.it" v-for="i in divisions" :key="i.id")

                        el-form-item
                            el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva
                            el-button(type="info" @click="newClient(1)" icon="el-icon-close") Annulla

            .el-row
                div(style="float: left")
                  el-button(type="primary" icon="el-icon-plus" @click="newClient") Nuovo cliente 

                  div(style="margin-left: 10px;display: inline;")
                    a(href="?archived=1" v-if="!is_archived")
                      el-button(type="warning" icon="el-icon-folder") Mostra archiviati
                    a(href="?archived=0" v-else)
                      el-button(type="warning" icon="el-icon-folder") Nascondi archiviati

                form.el-form(@submit="search_form($event)" style="display:flex;float:right")
                    a.el-tag.el-tag--dark.clear-filter(@click="filter_page('search', '')" href="#" v-if="q")
                      i.el-icon-close
                      | Pulisci ricerca
                    .el-form-item
                        input.el-input__inner(v-model="search" placeholder="Cerca")
                    .el-form-item
                        button.el-button.el-button--primary
                            i.el-icon-search

                el-form.filter
                    el-form-item(label="Filtra")
                      a.el-tag.el-tag--info(href="/crm" v-if="filters.length > 0 || filter_status")
                        i.el-icon-close(style="margin-right:5px")
                        | Annulla filtro
                    div(style="padding-bottom:10px")
                      span Stato: 
                      a.el-button.el-button--medium(
                        v-for="i in prospects"
                        :key="i"
                        @click="filter_page('status', i)"
                        :class="{'el-button--primary is-plain': filter_status == i}"
                      ) {{ i }}
                    div(style="padding-bottom:10px")
                      span Divisione: 
                      a.el-button.el-button--medium(
                        v-for="i in divisions"
                        :key="i.id"
                        @click="filter_page('filter', i.slug)"
                        :class="{'el-button--primary is-plain': filters.indexOf(i.slug) > -1}"
                      ) {{ i.slug.charAt(0).toUpperCase() + i.slug.slice(1) }}

                    div(v-if="filters.length > 0")
                      hr(style="width:100%")
                      br
                      el-button(type="info" size="medium") Totale: {{ count_responses[-2]  }}
                      el-button(type="info" size="medium") Totale responsi: {{ count_responses[-1]  }}
                      el-button(:class="{'el-button--primary is-plain': response == 0, 'el-button--info is-plain': response != 0}" size="medium" @click="filter_page('response', 0)") Non ancora conclusi: {{ count_responses[0]  }}
                      el-button(:class="{'el-button--primary is-plain': response == 1}" icon="el-icon-error" size="medium" @click="filter_page('response', 1)") Rifiutati: {{ count_responses[1]  }}
                      el-button(:class="{'el-button--primary is-plain': response == 2}" icon="el-icon-star-off" size="medium" @click="filter_page('response', 2)") Interessati: {{ count_responses[2]  }}
                      el-button(:class="{'el-button--primary is-plain': response == 3}" icon="el-icon-success" size="medium" @click="filter_page('response', 3)") Accettati: {{ count_responses[3]  }}
                      el-button(:class="{'el-button--primary is-plain': response == 4}" icon="el-icon-phone-outline" size="medium" @click="filter_page('response', 4)") Non rispondono: {{ count_responses[4]  }}
                      el-button(:class="{'el-button--primary is-plain': response == 5}" icon="el-icon-mic" size="medium" @click="filter_page('response', 5)") Segreteria: {{ count_responses[5]  }}
                      el-button(:class="{'el-button--primary is-plain': response == 6}" icon="el-icon-close" size="medium" @click="filter_page('response', 6)") Inesistenti: {{ count_responses[6]  }}

                el-table(
                  ref="clients_table"
                  :data="clients"
                  @selection-change="handleSelectionChange"
                  stripe
                  v-loading="loading"
                  @row-click="row_clicked"
                ).clients_table
                    el-table-column(type="selection" width="50")
                    el-table-column(type="expand" width="30")
                        template(slot-scope="scope")
                            blockquote 
                                b Note: 
                                | {{ scope.row.note }} 
                            div(v-if="scope.row.data")
                              p(v-for="key in Object.keys(scope.row.data)")
                                b {{ trans_keys(key) }}
                                | : {{ scope.row.data[key] }}
                            el-tabs(v-model="scope.row.division")
                                el-tab-pane(v-for="i in divisions" :key="i.id")
                                    span(slot="label")
                                        i.el-icon-success(style="color:#67C23A;margin-right:3px" v-if="last_status(scope.row, i.id) == 3")
                                        i.el-icon-error(style="color:#F56C6C;margin-right:3px" v-else-if="last_status(scope.row, i.id) == 1")
                                        i.el-icon-star-off(style="color:#E6A23C;margin-right:3px" v-else-if="last_status(scope.row, i.id) == 2")
                                        i.el-icon-remove(style="margin-right:3px" v-else)
                                        | {{ i.slug.charAt(0).toUpperCase() + i.slug.slice(1) }}
                                    el-dropdown(trigger="click" style="margin-right:10px" @command="handleCommand")
                                        el-button(type="primary" size="mini" style="margin-bottom:10px;") Nuovo responso
                                            i.el-icon-arrow-down.el-icon--right

                                        el-dropdown-menu(slot="dropdown")
                                            el-dropdown-item(icon="el-icon-error" :command="scope.row.id+',1,'+i.id") Rifiutato
                                            el-dropdown-item(icon="el-icon-star-off" :command="scope.row.id+',2,'+i.id") Interessato
                                            el-dropdown-item(icon="el-icon-success" :command="scope.row.id+',3,'+i.id") Accettato
                                            el-dropdown-item(icon="el-icon-phone-outline" divided :command="scope.row.id+',4,'+i.id") Non risponde
                                            el-dropdown-item(icon="el-icon-mic" :command="scope.row.id+',5,'+i.id") Segreteria
                                            el-dropdown-item(icon="el-icon-close" :command="scope.row.id+',6,'+i.id") Inesistente
                                    el-button(type="success" size="mini" style="margin-bottom:10px;" @click="sendContractViaEmail(i.id, scope.row.id)") Invia contratto
                                    h4 Responsi
                                    el-table(border ref="multipleTable" :data="scope.row.histories.filter(e => e.division_id == parseInt(i.id))")
                                        el-table-column(prop="created_at" label="Data")
                                        el-table-column(prop="user" label="Amministratore")
                                        el-table-column(label="Responso")
                                            template(slot-scope="scope")
                                                span(:style="{ color : status_in_color(scope.row.status) }") {{ scope.row.status|status_in_words }}
                                        el-table-column(label="Nota")
                                            template(slot-scope="scope")
                                                span {{ scope.row.note }}
                                                el-tag(v-if="scope.row.contract") Contratto inviato
                                        el-table-column
                                            template(slot-scope="history_row")
                                                el-button(icon="el-icon-delete" type="danger" @click="removeResponse(history_row.$index, scope.row.id)") Elimina

                                    br
                                    h4 Email a fornitori
                                    el-table(border ref="multipleTable" :data="scope.row.history")
                                        el-table-column(prop="created_at" label="Data")
                                        el-table-column(prop="text" label="Testo")
                                        el-table-column(prop="supplier" label="Fornitore")
                                        el-table-column(prop="note" label="Note")

                    el-table-column(label="Data" width="60")
                        template(slot-scope="scope")
                            i.el-icon-folder(style="color: #E6A23C; margin: 3px auto; float: left" v-if="scope.row.archived")
                            el-popover(
                              placement="top-start"
                              :title="scope.row.created_at"
                              width="40"
                              trigger="hover"
                            )
                                el-button(slot="reference" icon="el-icon-date" circle)
                    el-table-column(label="Ragione sociale" sortable prop="data.business_name")
                    el-table-column(label="Nome e cognome" sortable prop="name")
                        template(slot-scope="scope")
                          span {{ scope.row.name }}<br>{{ scope.row.surname }}
                    el-table-column(prop="email" label="Email" width="200" sortable)
                    el-table-column(prop="phone" label="Telefono" sortable)
                        template(slot-scope="scope")
                          span {{ scope.row.phone }}
                          img(src="@/assets/img/whatsapp.png" @click="waCall(scope.row.phone)" style="margin-left: 5px;display: inline" title="Apri Whatsapp")
                    el-table-column(label="Indirizzo" prop="data.address" sortable)
                        template(slot-scope="scope")
                          span(v-if="scope.row.data") {{ scope.row.data.address }}
                    el-table-column(prop="ref" label="Sorgente" sortable)
                    el-table-column(prop="prospect" label="Stato" sortable)
                    el-table-column(label="Divisioni")
                        template(slot-scope="scope")
                          el-tag(v-for="division in scope.row.divisions" type="info" effect="dark" :key="division.id") {{ division }}
                    el-table-column(align="right")
                        template(slot-scope="scope")
                            div(v-for="filter in filters" v-if="filters.length > 0")
                                span(style="color:#67C23A;margin-right:3px" v-if="last_status(scope.row, filter) == 3")
                                    i.el-icon-success
                                    | {{ filter|title }}
                                span(style="color:#F56C6C;margin-right:3px" v-else-if="last_status(scope.row, filter) == 1")
                                    i.el-icon-error
                                    | {{ filter|title }}
                                span(style="color:#E6A23C;margin-right:3px" v-else-if="last_status(scope.row, filter) == 2")
                                    i.el-icon-star-off
                                    | {{ filter|title }}
                                span(style="margin-right:3px" v-else)
                                    i.el-icon-remove
                                    | {{ filter|title }}
                            el-button(type="secondary" size="mini" style="margin-bottom:10px;") Responso
                                i.el-icon-arrow-down.el-icon--right

                            el-dropdown(@command="handleEditCommand")
                                el-button(type="primary" icon="el-icon-setting")
                                el-dropdown-menu(slot="dropdown")
                                    el-dropdown-item(icon="el-icon-connection" :command="'send,'+scope.$index" v-if="scope.row.divisions.length > 0") Invia a fornitore
                                    el-dropdown-item(icon="el-icon-edit" :command="'edit,'+scope.$index") Modifica
                                    el-dropdown-item(icon="el-icon-folder" :command="'delete,'+scope.row.id" ) 
                                        span(v-if="!scope.row.archived") Archivia
                                        span(v-else) Disarchivia

                br
                br
                el-button(size="mini" type="warning" icon="el-icon-folder" @click="handleArchiveSelects()") Archivia righe selezionate   
                .el-pagination.is-background(style="margin-top: 20px" v-if="clients.length > 1")
                  .el-pager.is-background
                    li.number(v-for="i in Math.ceil(pagesize/350)" :class="{active: i == n+1 || i == 0 && n < 1}")
                      a(href="#" @click="get_page(1)" v-if="i < 2") 1
                      a(href="#" @click="get_page(i)" v-else) {{ i }}
</template>

<script>
import Sidebar from "./Sidebar";

export default {
  name: "Clients",
  components: {
    Sidebar,
  },
  props: ["page"],
  data() {
    return {
      user_id: "",
      user_token: "",
      n: 0,
      success: "",
      error: "",
      error_detail: "",
      createClient: 0,
      selections: [],
      clients: [],
      agents: [],
      refs: [],
      search: "",
      editform_id: null,
      is_archived: false,
      clientform: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        ref: "",
        prospect: "",
        note: "",
        divisions: [],
        data: {
          business_name: "",
          address: "",
          vat: "",
          category: "",
          cod: "",
          site: "",
          phone2: "",
          other_phones: [],
          birthday: "",
          skype: "",
          pec: "",
        },
      },
      pagesize: 0,
      prospects: [
        "Ex cliente",
        "Prospect",
        "Cliente",
        "Prospect Hot",
        "Escluso",
      ],
      divisions: [],
      q: null,
      filters: [],
      filter_status: null,
      response: null,
      contract: false,
      send_to_supplier: null,
      send_to_supplier_id: 0,
      supplierform: {
        suppliers: [],
        typegroup: [],
        clients: {},
        note: "",
      },
      typegroups: [],
      loading: false,
      count_responses: {},
    };
  },
  methods: {
    waCall(phone) {
      if (phone[0] == "+") phone = phone.substr(1);
      else phone = "39" + phone;
      window.open("https://api.whatsapp.com/send/?phone=" + phone);
    },
    addOtherPhone() {
      if (this.clientform.data.other_phones) {
        this.clientform.data.other_phones.push("");
      } else {
        this.$set(this.clientform.data, "other_phones", [""]);
      }
    },
    removeOtherPhone(index) {
      this.clientform.data.other_phones.splice(index, 1);
    },
    row_clicked(row) {
      this.$refs["clients_table"].toggleRowExpansion(row);
    },
    handleCommand(command) {
      command = command.split(",");
      this.handleResponse(
        parseInt(command[0]),
        parseInt(command[1]),
        command[2].toLowerCase()
      );
    },
    handleEditCommand(command) {
      command = command.split(",");
      if (command[0] == "edit") this.handleEdit(command[1]);
      else if (command[0] == "send") this.handleSupplier(command[1]);
      else this.handleDelete(command[1]);
    },
    trans_keys(key) {
      let dic = {
        name: "Nome",
        surname: "Cognome",
        email: "Email",
        phone: "Telefono",
        phone2: "Secondo telefono",
        client_type: "Tipo di cliente",
        km: "Kilometri",
        time: "Durata",
        change: "Cambio",
        budget: "Budget mensile",
        advance: "Anticipo",
        note: "Note",
        category: "Categoria",
        cf: "CF",
        piva: "P.IVA",
        business_name: "Ragione sociale",
        address: "Indirizzo",
        vat: "P.IVA",
        street_n: "Numero civico",
        city: "Città",
        postal_code: "CAP",
        administrative_area_level_1: "Regione",
        skype: "Skype",
        table: "Tabella",
        label: "Prodotto",
        qta: "Quantità",
        cadency: "Cadenza",
        birthday: "Data di nascita",
        cod: "Codice univoco",
        site: "Sito web",
        pec: "PEC",
      };

      return dic[key];
    },
    search_form(e) {
      this.filter_page("search", this.search);
      e.preventDefault();
    },
    filter_page(key, value) {
      var path = "/crm";
      if (this.n > 1 && (key != "search" || key != "response"))
        path += `/page/${this.n}`;
      path += "?f=1";
      switch (key) {
        case "filter": {
          const index = this.filters.indexOf(value);
          if (index == -1) this.filters.push(value);
          else this.filters.splice(index, 1);
          break;
        }
        case "search":
          path += `&q=${value}`;
          this.search = null;
          break;
        case "status": {
          if (this.filter_status != value) path += `&status=${value}`;
          this.filter_status = null;
          break;
        }
        case "response": {
          if (this.response != value) path += `&response=${value}`;
          this.response = null;
          break;
        }
      }

      if (this.filters.length > 0) {
        for (const elem of this.filters) path += "&filters[]=" + elem;
      }
      if (this.search) {
        path += "&q=" + this.search;
      }
      if (this.filter_status) {
        path += "&status=" + this.filter_status;
      }
      if (this.response) {
        path += "&response=" + this.response;
      }

      window.location.href = path;
    },
    handleSupplier(index) {
      if (index == -1) {
        this.send_to_supplier = null;
        this.supplierform.clients = {};
        this.supplierform.typegroup = [];
        this.send_to_supplier_id = 0;
      } else {
        this.send_to_supplier = this.clients[index];
        this.send_to_supplier_id = index;
        this.supplierform.clients = {
          name: true,
          surname: true,
          email: true,
          phone: true,
          note: true,
          data: {},
        };
        if (this.clients[index].data)
          Object.keys(this.clients[index].data).forEach((e) => {
            this.supplierform.clients.data[e] = true;
          });

        for (const division of this.send_to_supplier.divisions) {
          this.supplierform.typegroup.push(
            ...this.typegroups.filter((e) => e.name == division)[0].suppliers
          );
        }
      }
    },
    handleResponse(client, status, division) {
      if (status == 3) {
        this.$confirm("", "Contratto inviato?", {
          confirmButtonText: "Sì",
          cancelButtonText: "No",
        })
          .then(() => {
            this.contract = true;
          })
          .catch(() => {
            this.contract = false;
          })
          .finally(() => {
            this.handleResponsePrompt(client, status, division);
          });
      } else {
        this.handleResponsePrompt(client, status, division);
      }
    },
    removeResponse(historyIndex, clientId) {
      for (const client of this.clients) {
        if (client.id == clientId) {
          this.$http
            .delete(
              `${process.env.VUE_APP_ROOT_API}/history/${client.histories[historyIndex].id}`,
              {
                headers: {
                  Authentication: this.$data.user_token,
                },
              }
            )
            .then((response) => {
              this.$message({
                type: "success",
                message: "Responso eliminato con successo",
              });
              client.histories.splice(historyIndex, 1);
            })
            .catch((e) => {
              this.$message({
                type: "error",
                message: `${e.status || "Errore durante l'eliminazione"} ${
                  e.statusText
                }`,
              });
            });
          break;
        }
      }
    },
    handleResponsePrompt(client, status, division) {
      this.$prompt("Scrivi un eventuale nota nel box qui di seguito", "Note", {
        confirmButtonText: "Salva",
        cancelButtonText: "Annulla",
        inputType: "textarea",
      })
        .then(({ value }) => {
          let params = {
            note: value,
            status: status,
            user_id: this.$data.user_id,
            client_id: client,
            division_id: division,
            contract: this.contract,
          };

          this.$http
            .put(
              `${process.env.VUE_APP_ROOT_API}/client/${client}/response`,
              { response: status },
              {
                headers: {
                  Authentication: this.$data.user_token,
                },
              }
            )
            .then(() => {
              this.$http
                .post(`${process.env.VUE_APP_ROOT_API}/history`, params, {
                  headers: {
                    Authentication: this.$data.user_token,
                  },
                })
                .then((response) => {
                  this.$swal({
                    buttons: false,
                    timer: 1000,
                    title: "Responso aggiunto con successo",
                    icon: "success",
                  });
                  response = JSON.parse(response.bodyText).result;
                  console.log(status, client, response.division);
                  for (let i = 0; this.clients.length; ++i) {
                    if (this.clients[i].id == client) {
                      this.clients[i].status = status;
                      this.clients[i].histories.push({
                        client_id: client,
                        note: value,
                        status: status,
                        contract: this.contract,
                        created_at: response.history.created_at,
                        user: response.history.user.email,
                        division_id: response.division,
                      });
                      break;
                    }
                  }
                })
                .catch((e) => {
                  this.$swal({
                    buttons: false,
                    timer: 1000,
                    title: `${e.status} ${e.statusText}`,
                    icon: "error",
                  });
                });
            })
            .catch((e) => {
              this.$swal({
                buttons: false,
                timer: 1000,
                title: `${e.status} ${e.statusText}`,
                icon: "error",
              });
            });
        })
        .catch(() => {
          this.$swal({
            buttons: false,
            timer: 1000,
            title: "Responso non aggiunto",
            icon: "info",
          });
        });
    },
    sendContractViaEmail(division, client) {
      let params = {
        note: null,
        status: 2,
        user_id: this.$data.user_id,
        client_id: client,
        division_id: String(division),
        contract: true,
      };

      this.$http
        .put(
          `${process.env.VUE_APP_ROOT_API}/client/${client}/response`,
          { response: 2 },
          {
            headers: {
              Authentication: this.$data.user_token,
            },
          }
        )
        .then(() => {
          this.$http
            .post(`${process.env.VUE_APP_ROOT_API}/history/send`, params, {
              headers: {
                Authentication: this.$data.user_token,
              },
            })
            .then((response) => {
              this.$swal({
                buttons: false,
                timer: 1000,
                title: "Responso aggiunto con successo",
                icon: "success",
              });
              response = JSON.parse(response.bodyText).result;
              for (let i = 0; this.clients.length; ++i) {
                if (this.clients[i].id == client) {
                  this.clients[i].status = 2;
                  this.clients[i].histories.push({
                    client_id: client,
                    note: 'Offerta inviata in data '+response.history.created_at,
                    status: 2,
                    contract: true,
                    created_at: response.history.created_at,
                    user: response.history.user.email,
                    division_id: response.division,
                  });
                  break;
                }
              }
            })
            .catch((e) => {
              this.$swal({
                buttons: false,
                timer: 1000,
                title: `${e.status} ${e.statusText}`,
                icon: "error",
              });
            });
        })
        .catch((e) => {
          this.$swal({
            buttons: false,
            timer: 1000,
            title: `${e.status} ${e.statusText}`,
            icon: "error",
          });
        });
    },
    handleSelectionChange(val) {
      this.selections = val;
    },
    handleArchiveSelects() {
      let clientids = this.$data.selections.map((e) => e.id);
      if (clientids.length < 1) {
        this.$message({
          type: "info",
          message: "Nessuna riga selezionata",
        });
        return;
      }
      this.$http
        .put(
          `${process.env.VUE_APP_ROOT_API}/client/archive`,
          { id: clientids },
          {
            headers: {
              Authentication: this.$data.user_token,
            },
          }
        )
        .then(() => {
          this.$message({
            type: "success",
            message: "Cliente modificato con successo",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        });
    },
    newClient(reset) {
      this.$data.createClient = !this.$data.createClient;
      if (reset) {
        this.$data.editform_id = null;
        this.$data.clientform = {
          name: "",
          surname: "",
          email: "",
          phone: "",
          ref: "",
          prospect: "",
          note: "",
          divisions: [],
          data: {
            business_name: "",
            address: "",
            vat: "",
            category: "",
            cod: "",
            site: "",
            phone2: "",
            other_phones: [],
            birthday: "",
            skype: "",
            pec: "",
          },
        };
      }
    },
    handleEdit(index) {
      this.$data.editform_id = this.$data.clients[index].id;
      this.$data.clientform = this.$data.clients[index];
      if (this.clients[index].data.birthday) {
        const s_date = this.clients[index].data.birthday.split("/");
        this.clientform.data.birthday = new Date(
          s_date[2],
          s_date[1] - 1,
          s_date[0]
        );
      }
      this.newClient();
    },
    handleDelete(clientId) {
      this.$http
        .put(
          `${process.env.VUE_APP_ROOT_API}/client/archive`,
          { id: clientId },
          {
            headers: {
              Authentication: this.$data.user_token,
            },
          }
        )
        .then(() => {
          this.$message({
            type: "success",
            message: "Cliente archiviato con successo",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        });
    },
    onSubmitSupplier() {
      this.$refs["supplierform"].validate((valid) => {
        if (valid) {
          let params = {
            suppliers: this.$data.supplierform.suppliers,
            members: {},
            note: this.$data.supplierform.note,
            name:
              this.send_to_supplier.name + " " + this.send_to_supplier.surname,
          };
          params.members[this.send_to_supplier.id] = this.supplierform.clients;

          if (params.suppliers.length == 0) {
            this.$message({
              type: "error",
              message: "Non hai selezionato nessun fornitore",
            });
            return;
          }

          this.$data.loading = true;
          this.$http
            .post(`${process.env.VUE_APP_ROOT_API}/clients/suppliers`, params, {
              headers: {
                Authentication: this.$data.user_token,
              },
            })
            .then((response) => {
              const data = response.body.result.records;
              data.forEach((i) => {
                this.clients[this.send_to_supplier_id].history.push({
                  created_at: i.created_at,
                  note: i.note,
                  supplier: i.supplier.email,
                  text: i.text,
                });
              });
              this.handleSupplier(-1);
              this.$message({
                type: "success",
                message: "Email inviate",
              });
              setTimeout(() => {
                this.$data.loading = false;
              }, 100);
            })
            .catch((e) => {
              this.$message({
                type: "error",
                message: `${e.status} ${e.statusText}`,
              });
              setTimeout(() => {
                this.$data.loading = false;
              }, 1000);
            });
        }
      });
    },
    onSubmit() {
      this.$refs["clientform"].validate((valid) => {
        if (valid) {
          if (this.clientform.data.birthday) {
            let d = this.clientform.data.birthday;
            d.setDate(d.getDate());
            let day = d.getDate();
            if (day < 10) day = "0" + day;
            let month = d.getMonth() + 1;
            if (month < 10) month = "0" + month;
            const year = d.getFullYear();
            this.clientform.data.birthday = `${day}/${month}/${year}`;
          }
          let params = {
            name: this.clientform.name,
            surname: this.clientform.surname,
            email: this.clientform.email.toLowerCase(),
            phone: this.clientform.phone,
            ref: this.clientform.ref,
            divisions: this.clientform.divisions,
            prospect: this.clientform.prospect,
            note: this.clientform.note,
            data: this.clientform.data,
          };

          var action = {
            client: {
              method: "",
              url: "",
              headers: {
                Authentication: localStorage.getItem(
                  process.env.VUE_APP_USER_TOKEN
                ),
              },
            },
          };
          if (this.$data.editform_id) {
            let path = `${process.env.VUE_APP_ROOT_API}/client/${this.$data.editform_id}`;
            action.client.method = "PUT";
            action.client.url = path;
          } else {
            let path = `${process.env.VUE_APP_ROOT_API}/client`;
            action.client.method = "POST";
            action.client.url = path;
          }

          var resource = this.$resource("client{/}", {}, action);
          resource
            .client(params)
            .then(() => {
              this.$data.success = true;
              this.$data.error = false;
              this.$swal({
                buttons: false,
                timer: 1000,
                title: "Cliente salvato!",
                icon: "success",
              });
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((e) => {
              if (e.body.result["detail"]) {
                this.error_detail = e.body.result.detail;
              } else {
                this.error_detail = e.body.result;
              }
              this.$data.error = `${e.body.status} ${e.body.result}`;
              this.$swal({
                buttons: false,
                timer: 1000,
                title: this.$data.error,
                icon: "error",
              });
            });
        } else {
          this.$swal({
            buttons: false,
            timer: 1000,
            title: "Non hai compilato tutto il form in modo corretto",
            icon: "error",
          });
          setTimeout(() => {
            document.querySelector(".card-form").scroll({
              top: 0, behavior: 'smooth'
            })
          }, 1500);

          return false;
        }
      });
    },
    status_in_color(status) {
      let status_color;
      switch (status) {
        case 0:
          status_color = "#C0C4CC";
          break;
        case 1:
          status_color = "#F56C6C";
          break;
        case 2:
          status_color = "#E6A23C";
          break;
        case 3:
          status_color = "#67C23A";
          break;
        default:
          status_color = "#606266";
      }

      return status_color;
    },
    last_status(row, division) {
      if (typeof division == "string") division = division.toLowerCase();
      var histories;
      if (Number.isInteger(division)) {
        histories = row.histories.filter((e) => e.division_id == division);
      } else {
        histories = row.histories.filter((e) => {
          const div = this.divisions.filter((x) => x.slug == division)[0];
          return e.division_id == div.id;
        });
      }
      if (histories.length > 0) {
        return histories[histories.length - 1].status;
      }

      return -1;
    },
    get_page(num) {
      var path = "/crm";
      if (num > 1) path += `/page/${num}`;
      path += "?f=1";

      if (this.filters.length > 0) {
        for (const elem of this.filters) path += "&filters[]=" + elem;
      }
      if (this.search) {
        path += "&q=" + this.search;
      }
      if (this.filter_status) {
        path += "&status=" + this.filter_status;
      }
      if (this.response) {
        path += "&response=" + this.response;
      }

      window.location.href = path;
    },
  },
  filters: {
    title(text) {
      return text[0].toUpperCase() + text.slice(1);
    },
    status_in_words(status) {
      let status_text;
      switch (status) {
        case 1:
          status_text = "Rifiutato";
          break;
        case 2:
          status_text = "Interessato";
          break;
        case 3:
          status_text = "Accettato";
          break;
        case 4:
          status_text = "Non risponde";
          break;
        case 5:
          status_text = "Segreteria";
          break;
        case 6:
          status_text = "Inesistente";
          break;
        default:
          status_text = "Nessuno";
      }

      return status_text;
    },
  },
  created() {
    this.n = this.$props.page;
    this.is_archived = this.$route.query.archived == "1";
    this.search = this.q = this.$route.query.q;
    if (this.n == undefined || this.n < 1) this.n = 0;
    else --this.n;
    this.$data.user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
    this.$data.user_token = localStorage.getItem(
      process.env.VUE_APP_USER_TOKEN
    );

    this.filter_status = this.$route.query.status;
    this.response = this.$route.query.response;
    if (this.$route.query["filters[]"]) {
      this.filters = this.$route.query["filters[]"];
      if (typeof this.filters == "string") this.filters = this.filters.split();
    }

    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/refs`, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        this.refs = response.body.result.map((x) => x.name);
      });

    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/typegroups`, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        this.typegroups = response.body.result;
      });

    this.$http
      .get(
        `${process.env.VUE_APP_ROOT_API}/divisions?params=id,name,slug&all=1`
      )
      .then((response) => {
        this.divisions = JSON.parse(response.bodyText).result;
        if (this.filters.length > 0) {
          let check = false;
          this.divisions.forEach((e) => {
            if (this.filters.indexOf(e.slug) > -1) check = true;
          });
          if (!check) window.location.href = "/crm";
        }
      });

    let path = `${process.env.VUE_APP_ROOT_API}/clients?begin=${
      this.n
    }&end=350&search=${this.search || ""}`;
    if (this.filters.length > 0) path += "&division=" + this.filters.join();
    if (this.filter_status) path += `&status=${this.filter_status}`;
    if (this.response) path += `&response=${this.response}`;
    if (this.is_archived) path += "&is_archived=1";

    this.loading = true;
    this.$http
      .get(path, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        let data = JSON.parse(response.bodyText).result;
        this.pagesize = data.size;
        this.clients = data.clients;
        this.clients.forEach((e) => {
          e.division = "0";
        });
        this.loading = false;
        this.count_responses = data.stats;
      });

    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/agents`, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        let data = JSON.parse(response.bodyText).result;
        this.agents = data.map((x) => x.name + " <" + x.email + ">");
      });
  },
};
</script>

<style lang="sass">
blockquote
    margin: 10px 0
    border: 1px #ebeef5 solid
    padding: 3px
    font-style: italic
.el-pager
  display: flex
  flex-wrap: wrap
  li
    padding: 0
  a
    width: 100%
    height: 100%
    display: block
.clear-filter
  margin: 5px
  i
    margin: 0 5px 0 0

.filter
  margin-top: 80px
  label
    margin-top: 13px
  .el-button
    padding: 10px 18px

.el-tabs__header
  z-index: 0
.clients_table
  td
    cursor: pointer
    &:first-child,
    &:last-child
      cursor: default
</style>
