<template lang="pug">
    .main
        Sidebar(active="8")
        section.cms
            .card-form(v-if="createAgent")
                el-card.box-card
                    .alert
                        el-alert(title="Agente salvato!" type="success" show-icon v-if="success")
                        el-alert(title="Errore!" type="error" :label="error" show-icon v-if="error")

                    el-form(label-width="120px" :model="agentform" ref="agentform")
                        el-form-item(label="Nome" prop="name" required)
                            el-input(type="input" v-model="agentform.name")

                        el-form-item(label="Sales code" prop="code" required)
                            el-input(type="input" v-model="agentform.code")

                        el-form-item(label="Telefono mobile" prop="phone" required)
                            el-input(type="input" v-model="agentform.phone")

                        el-form-item(label="Email" prop="email" required)
                            el-input(type="email" v-model="agentform.email")

                        el-form-item(label="Indirizzo" prop="address")
                            el-input(type="input" v-model="agentform.data.address")

                        el-form-item(label="Ragione sociale" prop="business_name")
                            el-input(type="input" v-model="agentform.data.business_name")

                        el-form-item(label="Divisione" prop="typegroups")
                            el-select(v-model="agentform.typegroups" multiple filterable style="width:100%")
                                el-option(:value="i.name" v-for="i in typegroups" :key="i.id")

                        el-form-item
                            el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva
                            a.el-button.el-button--info(href="/crm/agents")
                              i.el-icon-close
                              | Annulla

            .el-row
                el-button(type="primary" icon="el-icon-plus" @click="newAgent") Nuovo agente
                br
                br
                el-table(ref="multipleTable" :data="agents.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.email.toLowerCase().includes(search.toLowerCase()))" @selection-change="handleSelectionChange" stripe)
                    el-table-column(type="selection" width="50")
                    el-table-column(label="Ragione sociale")
                        template(slot-scope="scope")
                          span(v-if="scope.row.data") {{ scope.row.data.business_name }}
                    el-table-column(prop="name" label="Nome")
                    el-table-column(prop="code" label="Sales code")
                    el-table-column(prop="email" label="Email" width="200")
                    el-table-column(prop="phone" label="Telefono")
                    el-table-column(label="Indirizzo")
                        template(slot-scope="scope")
                          span(v-if="scope.row.data") {{ scope.row.data.address }}
                    el-table-column(label="Divisioni")
                        template(slot-scope="scope")
                          el-tag(v-for="division in scope.row.typegroups" type="info" effect="dark" :key="division.id") {{ division }}

                    el-table-column(align="right")
                        template(slot="header" slot-scope="scope")
                            el-input(v-model="search" size="mini" placeholder="Cerca")

                        template(slot-scope="scope")
                            el-button(size="mini" icon="el-icon-edit" title="Modifica" @click="handleEdit(scope.$index)")
                            el-button(size="mini" icon="el-icon-delete" title="Elimina" type="danger" @click="handleDelete(scope.row.id)")

                br
                br
                el-button(size="mini" type="danger" icon="el-icon-delete" @click="handleDeleteSelects()") Elimina righe selezionate

</template>

<script>
import Sidebar from "./Sidebar";

export default {
  name: "Agents",
  components: {
    Sidebar,
  },
  data() {
    return {
      user_id: "",
      user_token: "",
      success: "",
      error: "",
      createAgent: 0,
      selections: [],
      agents: [],
      typegroups: [],
      search: "",
      editform_id: null,
      agentform: {
        name: "",
        code: "",
        email: "",
        phone: "",
        typegroups: [],
        data: {
          business_name: "",
          address: "",
        },
      },
    };
  },
  filters: {},
  methods: {
    handleSelectionChange(val) {
      this.selections = val;
    },
    handleDeleteSelects() {
      let agentids = this.$data.selections.map((e) => e.id);
      if (agentids.length < 1) {
        this.$message({
          type: "info",
          message: "Nessuna riga selezionata",
        });
        return;
      }
      this.$http
        .delete(`${process.env.VUE_APP_ROOT_API}/agents?id=${agentids}`, {
          headers: {
            Authentication: this.$data.user_token,
          },
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "Agenti eliminati con successo",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        });
    },
    newAgent(reset) {
      this.$data.createAgent = !this.$data.createAgent;
      if (reset) {
        this.$data.editform_id = null;
        this.$data.agentform = {
          name: "",
          code: "",
          email: "",
          phone: "",
          typegroups: [],
          data: {
            business_name: "",
            address: "",
          },
        };
      }
    },
    handleEdit(index) {
      this.$data.editform_id = this.$data.agents[index].id;
      this.$data.agentform = this.$data.agents[index];
      if (!this.agentform.data) {
        this.agentform.data = {
          business_name: "",
          address: "",
        };
      }
      this.newAgent();
    },
    handleDelete(agentId) {
      this.$http
        .delete(`${process.env.VUE_APP_ROOT_API}/agent/${agentId}`, {
          headers: {
            Authentication: this.$data.user_token,
          },
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "Agente eliminato con successo",
          });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          this.$message({
            type: "error",
            message: `${e.status} ${e.statusText}`,
          });
        });
    },
    onSubmit() {
      this.$refs["agentform"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.$data.agentform.name,
            code: this.$data.agentform.code,
            email: this.$data.agentform.email,
            phone: this.$data.agentform.phone,
            typegroups: this.$data.agentform.typegroups,
            data: this.$data.agentform.data,
          };

          var action = {
            agent: {
              method: "",
              url: "",
              headers: {
                Authentication: localStorage.getItem(
                  process.env.VUE_APP_USER_TOKEN
                ),
              },
            },
          };
          if (this.$data.editform_id) {
            let path = `${process.env.VUE_APP_ROOT_API}/agent/${this.$data.editform_id}`;
            action.agent.method = "PUT";
            action.agent.url = path;
          } else {
            let path = `${process.env.VUE_APP_ROOT_API}/agent`;
            action.agent.method = "POST";
            action.agent.url = path;
          }

          var resource = this.$resource("agent{/}", {}, action);
          resource
            .agent(params)
            .then(() => {
              this.$data.success = true;
              this.$data.error = false;
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((e) => {
              this.$data.error = `${e.body.status} ${e.body.result}`;
            });
        } else {
          return false;
        }
      });
    },
  },
  created() {
    this.$data.user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
    this.$data.user_token = localStorage.getItem(
      process.env.VUE_APP_USER_TOKEN
    );

    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/agents`, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        let data = JSON.parse(response.bodyText).result;
        this.$data.agents = data;
      });

    this.$http
      .get(`${process.env.VUE_APP_ROOT_API}/typegroups`, {
        headers: {
          Authentication: this.$data.user_token,
        },
      })
      .then((response) => {
        let data = JSON.parse(response.bodyText).result;
        data.forEach((i) => {
          if (i.has_suppliers) this.$data.typegroups.push(i);
        });
      });
  },
};
</script>
