<template lang="pug">
    .users
        Sidebar(active="1")
        section.cms
            .card-form(v-if="createUser || userId")
                el-card.box-card
                    .alert
                        el-alert(title="Utente salvato!" type="success" show-icon v-if="success")
                        el-alert(title="Errore!" type="error" :label="error" show-icon v-if="error")

                    el-form(label-width="120px" :model="userform" ref="userform")
                        el-form-item(label="Nome" prop="name" required)
                            el-input(type="input" v-model="userform.name")

                        el-form-item(label="Email" prop="email" required)
                            el-input(type="email" v-model="userform.email")

                        el-form-item(label="Password" prop="password" v-if="userId")
                            el-input(:type="psw_format" v-model="userform.password" placeholder="Lascia vuota per non modificare quella attuale" minlength="8")
                            button(type="button" class="check-psw" @click="change_psw_format()" :class="{see: psw_format == 'password',nosee: psw_format == 'text'}")
                            span(style="font-size: 12px;color:#d9d9d9;float:left") Minimo 8 caratteri
                        el-form-item(label="Password" prop="password" v-else required)
                            el-input(:type="psw_format" v-model="userform.password" minlength="8")
                            button(type="button" class="check-psw" @click="change_psw_format()" :class="{see: psw_format == 'password',nosee: psw_format == 'text'}")
                        el-form-item(label="Amministratore" prop="is_admin")
                            el-switch(v-model="userform.is_admin")
                        el-form-item
                            el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva modifiche
                            el-button(type="info" @click="newUser()" icon="el-icon-close" v-if="!userId") Annulla
                            a(class="el-button el-button--info" href="/app/dashboard" v-else) <i class="el-icon-close"></i> Annulla                

            .el-row(v-if="!userId")
                el-button(type="primary" icon="el-icon-plus" @click="newUser") Nuovo utente
                .el-col(v-for="user in users")
                    el-card(shadow="hover" style="margin: 5px auto; width:100%")
                        div(style="float:left")
                            h2 {{ user.name }}
                            span(style="color:#d9d9d9") {{ user.is_admin ? "Amministratore" : "" }}
                        .card-actions
                          span(style="float:right") Creazione: {{ user.created_at }}
                          div
                            a(class="el-button el-button--info" :href="'?id='+user.id") <i class="el-icon-edit"></i> Modifica
                            el-button(type="danger" @click="deleteUser(user.id)" icon="el-icon-delete") Elimina
</template>

<script>
import Sidebar from "./Sidebar";

export default {
  name: "News",
  components: {
    Sidebar,
  },
  props: ["userId"],
  data() {
    return {
      user_id: "",
      user_token: "",
      users: [],
      success: "",
      error: "",
      createUser: false,
      psw_format: "password",
      userform: {
        name: "",
        email: "",
        password: "",
      },
    };
  },
  methods: {
    change_psw_format() {
      this.$data.psw_format =
        this.$data.psw_format == "password" ? "text" : "password";
    },
    onSubmit() {
      this.$refs["userform"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.$data.userform.name,
            email: this.$data.userform.email,
            password: this.$data.userform.password,
            is_admin: this.$data.userform.is_admin,
          };
          var path;
          if (this.$props.userId) {
            path = `${process.env.VUE_APP_ROOT_API}/user/${this.$props.userId}`;
          } else {
            path = `${process.env.VUE_APP_ROOT_API}/user`;
          }

          if (this.$props.userId) {
            this.$http
              .put(path, params, {
                headers: {
                  Authentication: localStorage.getItem(
                    process.env.VUE_APP_USER_TOKEN
                  ),
                },
              })
              .then(() => {
                this.$data.success = true;
                this.$swal({
                  buttons: false,
                  timer: 1000,
                  title: "Utente salvato!",
                  icon: "success",
                });
                this.$data.error = false;
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((e) => {
                this.$data.error = `${e.body.status} ${e.body.result}`;
              });
          } else {
            this.$http
              .post(path, params, {
                headers: {
                  Authentication: localStorage.getItem(
                    process.env.VUE_APP_USER_TOKEN
                  ),
                },
              })
              .then(() => {
                this.$data.success = true;
                this.$data.error = false;
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((e) => {
                this.$data.error = `${e.body.status} ${e.body.result}`;
              });
          }
        } else {
          return false;
        }
      });
    },
    newUser() {
      this.$data.createUser = !this.$data.createUser;
    },
    deleteUser(userId) {
      this.$http
        .delete(`${process.env.VUE_APP_ROOT_API}/user/${userId}`, {
          headers: {
            Authentication: localStorage.getItem(
              process.env.VUE_APP_USER_TOKEN
            ),
          },
        })
        .then(() => {
          window.location.href = "/app/dashboard";
        });
    },
  },
  created() {
    if (
      !(
        localStorage.getItem(process.env.VUE_APP_USER_ID) &&
        localStorage.getItem(process.env.VUE_APP_USER_TOKEN)
      )
    ) {
      window.location.href = "/app";
    } else {
      this.$data.user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
      this.$data.user_token = localStorage.getItem(
        process.env.VUE_APP_USER_TOKEN
      );
    }

    if (!this.$props.userId) {
      this.$http
        .get(`${process.env.VUE_APP_ROOT_API}/users`, {
          headers: {
            Authentication: localStorage.getItem(
              process.env.VUE_APP_USER_TOKEN
            ),
          },
        })
        .then((response) => {
          let data = JSON.parse(response.bodyText).result;
          this.$data.users = data;
        });
    } else {
      this.$http
        .get(`${process.env.VUE_APP_ROOT_API}/user/${this.$props.userId}`, {
          headers: {
            Authentication: localStorage.getItem(
              process.env.VUE_APP_USER_TOKEN
            ),
          },
        })
        .then((response) => {
          let data = JSON.parse(response.bodyText).result;
          this.$data.userform = data;
        })
        .catch(() => {
          window.location.href = "/app/dashboard";
        });
    }
  },
};
</script>

<style lang="sass">
.el-card__body
	display: flow-root

.el-button
	font-family: 'system-ui', sans-serif

.card-actions
	float: right
</style>
