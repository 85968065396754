<template lang="pug">
    .divisions
        Sidebar(active="4")
        section.cms
            .card-form(v-if="createDivision")
                el-card.box-card
                    .alert
                        el-alert(title="Divisione salvata!" type="success" show-icon v-if="success")
                        el-alert(title="Errore!" type="error" :description="error" show-icon v-if="error")

                    el-form(label-width="120px" :model="divisionform" ref="divisionform")
                        el-form-item(label="Nome" prop="name" required)
                            el-input(type="input" v-model="divisionform.name")

                        el-form-item(label="Slug" prop="slug" required)
                            el-input(type="input" v-model="divisionform.slug")

                        el-form-item(label="ID Sendinblue" prop="sendinblue_id")
                            el-input(type="input" v-model="divisionform.sendinblue_id")

                        el-form-item(label="Template email" prop="template_pdf")
                            el-input(type="input" v-model="divisionform.template_pdf")

                        el-form-item
                            el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva modifiche
                            el-button(type="info" @click="newDivision()" icon="el-icon-close") Annulla

            .el-row(v-if="!divisionId")
                span Traduzione 
                el-button(type="primary" icon="el-icon-plus" @click="newDivision") Nuova divisione
                .el-col(v-for="division in divisions")
                    el-card(shadow="hover" style="margin: 5px auto; width:100%")
                        div(style="float:left")
                            h2 {{ division.name['it'] }}
                        .card-actions
                          span(style="float:right") Ultima modifica: {{ division.updated_at }}
                          div
                            a(class="el-button el-button--info" :href="'?id='+division.id+'&lang=it'") <i class="el-icon-edit"></i> Modifica
                            el-button(type="danger" @click="deleteDivision(division.id)" icon="el-icon-delete") Elimina

            .el-row(v-else-if="!lang")
                span Traduzione
                a.el-button(:href="`?id=${divisionId}&lang=${l}`" :class="{'is-disabled': l == lang}" v-for="l in availableLangs") {{ l }} 
                br
                br
                .alert
                    el-alert(title="Divisione salvata!" type="success" show-icon v-if="success")
                    el-alert(title="Errore!" type="error" :description="error" show-icon v-if="error")

                el-form(label-width="120px" :model="divisionform" ref="divisionform")
                    el-form-item
                        el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva modifiche
                        a.el-button.el-button--info(href="/app/divisions")
                            i.el-icon-back
                            span Torna indietro
                br
                br
            .el-row(v-else)
                a.el-button(:href="`?id=${divisionId}&lang=${l}`" :class="{'is-disabled': l == lang}" v-for="l in availableLangs") {{ l }} 
                br
                br
                .alert
                    el-alert(title="Divisione salvata!" type="success" show-icon v-if="success")
                    el-alert(title="Errore!" type="error" :description="error" show-icon v-if="error")

                el-form(label-width="120px" :model="divisionform" ref="divisionform")
                    el-form-item(:label="'Nome ('+lang+')'" prop="name" required)
                        el-input(type="input" v-model="divisionform.name[lang]")

                    el-form-item(label="Slug" prop="slug" required)
                        el-input(type="input" v-model="divisionform.slug")

                    el-form-item(label="ID Sendinblue" prop="sendinblue_id")
                        el-input(type="input" v-model="divisionform.sendinblue_id")

                    el-form-item(label="Template email" prop="template_pdf")
                        el-input(type="input" v-model="divisionform.template_pdf")

                    el-form-item
                        el-button(type="success" @click="onSubmit()" icon="el-icon-check") Salva modifiche
                        a.el-button.el-button--info(:href="'/app/divisions?id='+divisionId")
                            i.el-icon-back
                            span Torna indietro
                br
                br
</template>

<script>
import Sidebar from "./Sidebar";

export default {
  name: "Divisione",
  components: {
    Sidebar,
  },
  props: ["divisionId", "lang"],
  data() {
    return {
      user_id: "",
      user_token: "",
      divisions: [],
      success: "",
      error: "",
      createDivision: false,
      transDivisionChooseLang: false,
      divisionform: {
        name: "",
        slug: "",
        sendinblue_id: null,
        template_pdf: null,
      },
      availableLangs: process.env.VUE_APP_LANGS.split(","),
    };
  },
  methods: {
    onSubmit() {
      this.$refs["divisionform"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.$data.divisionform.name,
            slug: this.$data.divisionform.slug,
            sendinblue_id: this.$data.divisionform.sendinblue_id,
            template_pdf: this.$data.divisionform.template_pdf,
          };
          var path;
          if (this.$props.divisionId) {
            path = `${process.env.VUE_APP_ROOT_API}/division/${this.$props.divisionId}`;
          } else {
            path = `${process.env.VUE_APP_ROOT_API}/division`;
          }

          if (this.$props.divisionId) {
            this.$http
              .put(path, params, {
                headers: {
                  Authentication: localStorage.getItem(
                    process.env.VUE_APP_USER_TOKEN
                  ),
                },
              })
              .then(() => {
                this.$data.success = true;
                this.$swal({
                  buttons: false,
                  timer: 1000,
                  title: "Divisione salvata!",
                  icon: "success",
                });
                this.$data.error = false;
              })
              .catch((e) => {
                this.$data.error = `${e.body.status} ${e.body.result}`;
              });
          } else {
            this.$http
              .post(path, params, {
                headers: {
                  Authentication: localStorage.getItem(
                    process.env.VUE_APP_USER_TOKEN
                  ),
                },
              })
              .then(() => {
                this.$data.success = true;
                this.$data.error = false;
                setTimeout(() => {
                  window.location.reload();
                }, 1000);
              })
              .catch((e) => {
                this.$data.error = `${e.body.status} ${e.body.result}`;
              });
          }
        } else {
          return false;
        }
      });
    },
    newDivision() {
      this.$data.createDivision = !this.$data.createDivision;
    },
    deleteDivision(divisionId) {
      this.$http
        .delete(`${process.env.VUE_APP_ROOT_API}/division/${divisionId}`, {
          headers: {
            Authentication: localStorage.getItem(
              process.env.VUE_APP_USER_TOKEN
            ),
          },
        })
        .then(() => {
          window.location.href = "/app/divisions";
        })
        .catch((e) => {
          let message = `${e.status} ${e.statusText}`;
          if (e.body.result) {
            message = e.body.result;
          }
          this.$message({
            type: "error",
            message,
          });
        });
    },
  },
  created() {
    if (
      !(
        localStorage.getItem(process.env.VUE_APP_USER_ID) &&
        localStorage.getItem(process.env.VUE_APP_USER_TOKEN)
      )
    ) {
      window.location.href = "/app";
    } else {
      this.$data.user_id = localStorage.getItem(process.env.VUE_APP_USER_ID);
      this.$data.user_token = localStorage.getItem(
        process.env.VUE_APP_USER_TOKEN
      );
    }

    if (!this.$props.divisionId) {
      this.$http
        .get(
          `${process.env.VUE_APP_ROOT_API}/divisions?params=id,name,slug,sendinblue_id,template_pdf,updated_at`
        )
        .then((response) => {
          let data = JSON.parse(response.bodyText).result;
          this.$data.divisions = data;
        });
    } else {
      this.$http
        .get(
          `${process.env.VUE_APP_ROOT_API}/division/${this.$props.divisionId}?params=name,slug,sendinblue_id,template_pdf`
        )
        .then((response) => {
          let data = JSON.parse(response.bodyText).result;
          this.$data.divisionform = data;
        })
        .catch(() => {
          window.location.href = "/app/divisions";
        });
    }
  },
};
</script>

<style lang="sass">
.el-card__body
  display: flow-root

.card-form
  overflow: scroll

textarea
  height: 200px
</style>
