import Vue from "vue";

import Home from "../components/cms/Home";
import ForgotPassword from "../components/cms/ForgotPassword";
import Dashboard from "../components/cms/Dashboard";
import Pages from "../components/cms/Pages";
import Divisions from "../components/cms/Divisions";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/element-ui.common.js";
import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/it";

Vue.use(ElementUI, { locale });

const routes = [
  { path: "/", name: "home", component: Home },
  { path: "/app", name: "home", component: Home },
  { path: "/app/forgot", name: "forgotpassword", component: ForgotPassword },
  {
    path: "/app/forgot/:hash",
    name: "forgotpassword",
    component: ForgotPassword,
  },
  {
    path: "/app/dashboard",
    name: "dashboard",
    component: Dashboard,
    props: (route) => ({ userId: route.query.id }),
  },
  {
    path: "/app/pages/",
    name: "pages",
    component: Pages,
    props: (route) => ({ pageId: route.query.id, lang: route.query.lang }),
  },
  {
    path: "/app/divisions/",
    name: "Divisions",
    component: Divisions,
    props: (route) => ({ divisionId: route.query.id, lang: route.query.lang }),
  },
];

export default routes.map((route) => {
  return { ...route, meta: { public: false } };
});
